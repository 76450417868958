/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import one from "/public/images/room707-roound3(0)_13.png";
import two from "/public/images/harriet-amber-1.jpg";
import three from "/public/images/harriet-amber-2.jpg";
export const meta = {
  title: "Harriet Amber in the Conan&nbsp;Arcade",
  author: "Robin Sloan",
  credit: "Illustrations by Derrick Schultz",
  position: 3,
  slug: "harriet-amber-in-the-conan-arcade",
  category: "Fiction",
  type: "short-fiction",
  cover: {
    src: "harriet-amber-cover",
    static: "/images/harriet-amber-cover.jpg",
    alt: "Abstract animated image of a building imploding: gray concrete forms falling endlessly against a pale blue sky.",
    position: "before",
    type: "video"
  },
  paragraphs: "indents",
  byline_family: 1,
  pulled_quotes: true,
  excerpt: "It was ending, all of it.",
  weights: {
    hed: 500,
    byline: 400,
    text: 400,
    pullquote: 300
  },
  layouts: {
    "Graphik": {
      title: "Harriet Amber in the Conan Arcade"
    },
    "Original Sans": {
      title: "Harriet Amber in the <br/><b>Conan Arcade</b>"
    },
    "Le Jeune": {
      title: "<b>Harriet Amber</b> in the Conan Arcade",
      author: "Robin \nSloan"
    },
    "Lyon": {
      excerpt: "“If people expect something legendary it only has to be good. And, yeah, I can make a good burger.”"
    },
    "Eugenia": {
      title: "Harriet Amber in the Conan Arcade, a short story by Robin&nbsp;Sloan"
    }
  },
  cssSelectors: [{
    selector: ".body p",
    output: "body"
  }, {
    selector: ".article-title",
    output: "h1"
  }, {
    selector: "blockquote p",
    output: "blockquote"
  }, {
    selector: ":is(i, em, cite)",
    output: "em",
    excludeFromCSS: true
  }, {
    selector: ":is(b, strong)",
    output: "strong",
    excludeFromCSS: true
  }],
  pairings: {
    "Brunel Text Short <1.0625> {1.32}": ["Antique No 6 <0.9> {1.19}", "Brunel Deck <1.0125> {1.28}", "Caslon Doric <0.9643> {1.23}", "Duplicate Slab <1.0357> {1.1}", "Duplicate Sans <1.0357> {1.1}", "Eugenio Sans {1.135}", "Chiswick Grotesque <1.15> {1.12} [800]", "Graphik <0.9> {1.185}", "Review {1.21}", "Caslon Ionic <0.9643> {1.19} [700]", "Chiswick Sans <1.25> {1.2} (italic)", "Produkt <0.9643> {1.21} [600]", "Styrene B <0.9286> {1.15}"],
    "Atlas Grotesk {1.4}": ["Algebra Display <1.0357> {1.21}", "Atlas Grotesk {1.185}", "Austin <1.2143> {1.1}", "Brunel Deck <1.2143> {1.175}", "Eugenio Serif <1.1071> {1.15}", "Feature Deck <0.9286> {1.28}", "Frame Head <0.9286> {1.28}", "Le Jeune Deck {1.21}", "Terza Reader <0.9286> {1.3}", "Lyon Display <1.0357> {1.21}"],
    "Frame Text {1.4}": ["Chiswick Grotesque <1.0357> {1.2} [600]", "Frame Head <1.1> {1.28} (italic)", "Chiswick Sans <1.2> {1.15}", "Austin <1.2143> {1.1}", "Action Condensed Light <1.7143> {1} [300]", "Canela Deck <1.1> {1.2} [400]", "Caslon Doric <1.1> {1.23}", "Eugenio Sans <1.2> {1.135}", "Graphik <0.975> {1.185}", "Produkt <1.05> {1.21}", "Robinson <1.1> {1.21}", "Styrene A <0.9286> {1.25}"],
    "Lyon Text <1.0625> {1.4}": ["Marr Sans <1.0357> {1.28}", "Lyon Display <1.15> {1.21}", "Canela Deck {1.25} [600]", "Caslon Doric <1.1> {1.275}", "Chiswick Grotesque <1.0357> {1.2}", "Graphik {1.185}", "Produkt <0.9643> {1.21}", "Algebra Display <1.157> {1.21}", "Feature Deck <1.105> {1.32}", "Duplicate Slab <1.0357> {1.15}", "Review {1.21}", "Styrene A <0.9286> {1.25}"],
    "Caslon Doric <1.0625> {1.4}": ["Frame Head <0.9286> {1.28}", "Caslon Doric <0.9643> {1.23} [600]", "Caslon Ionic <0.9643> {1.19} (italic)", "Brunel Deck <1.15> {1.2}", "Chiswick Grotesque <1.125> {1.2} [800]", "Chiswick Sans <1.2> {1.21}", "Robinson <1.05> {1.21}", "Antique No 6 <0.9643> {1.19}", "Darby Sans Poster <1.25> {1.25}"],
    "Duplicate Ionic <1.1250> {1.4}": ["Duplicate Sans <1.1> {1.15}", "Duplicate Ionic <1.1> {1.15}", "Duplicate Slab <1.1> {1.15} [600]", "Le Jeune Deck <1.25> {1.21} (italic)", "Action Condensed Bold <1.7143> {1} [300]", "Action Text Dark <1.05> {1.21} [700]", "Robinson <1.05> {1.21} (italic)"],
    "Duplicate Sans <1.1250> {1.4}": ["Duplicate Ionic <1.1> {1.15}", "Duplicate Sans <1.1> {1.15} [500]", "Duplicate Slab <1.1> {1.15}", "Action Condensed Regular <1.7143> {1} [700]", "Feature Deck <1.105> {1.32}", "Le Jeune Deck <1.0357> {1.21}", "Frame Head <0.9286> {1.28}"]
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p",
      blockquote: "blockquote",
      hr: "hr",
      a: "a"
    }, props.components), {FontSection, Image} = _components;
    if (!FontSection) _missingMdxReference("FontSection", true);
    if (!Image) _missingMdxReference("Image", true);
    return _jsxs(_Fragment, {
      children: [_jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "The hotel was being demolished, and Harriet Amber’s career along with it."
        }), _jsx(_components.p, {
          children: "She had stayed at the Conan Arcade on her first trip to New York City, two decades ago. She chose it after seeing its lobby in a photo shoot published in a music magazine that boasted exactly one subscriber in Marquette, Michigan: Harriet Amber. The magazine provided most of her impression of the city: an endless hotel lobby full of slippery vampires, all unimpressed by the fact of having their pictures taken."
        }), _jsx(_components.p, {
          children: "That first trip had been pivotal. Harriet met the literary agent with whom she’d been corresponding, Elettra Brixi, a dream of bright brass, who became instantly — improbably — Harriet’s greatest ally. Over a three-hour lunch during which Harriet spoke not more than twelve words, Elettra made her believe she would have a writing career."
        }), _jsx(_components.p, {
          children: "The first of many times Elettra had been absolutely correct."
        }), _jsx(_components.p, {
          children: "That night, Harriet ordered room service, a lamb burger that came dressed with arugula and saffron aioli. She ate it looking out the window, watching the thrum and tangle of the street below, which, she realized matter-of-factly, contained in one block more life and drama than the entire town she had come from. She thought: maybe I should move here. But even then, she knew that what made publishers care about her — what made Elettra Brixi confident — was her status as a wry emissary from another place. Another planet."
        }), _jsx(_components.p, {
          children: "So, Harriet Amber went home to her ice planet, and her first novel sold twenty thousand copies, a lot back then — still a lot — especially for a slice-of-life story about chilly souls in Michigan’s Upper Peninsula. Her next novel sold about the same, and the next, and it became exactly what Elettra had predicted, that rarest thing for a writer: a career. The fourth book did even better; a Canadian director made a quiet little movie out of it. Harriet Amber bought a house on Prospect Street for a pittance and socked the rest of her royalties away."
        }), _jsx(_components.p, {
          children: "She kept this rhythm up over two decades, a novel every year, and every year also a trip to New York: her great ritual. She stayed always at the Conan Arcade, in the same room if she could, 707, on the top floor. A great number, 707. She slipped it into her novels."
        }), _jsx(_components.p, {
          children: "Now, preparing for her annual trip, she found the Conan Arcade’s website offline. This, on its own, was not alarming; the website was only slightly less creaky than the hotel. But a call to the front desk revealed the news: the Conan Arcade was closed, scheduled for demolition and, eventually, replacement by a gleaming slab of condominiums with very fast internet."
        }), _jsx(_components.p, {
          children: "“End of an era,” Harriet murmured to herself, but in fact the era had already ended: she was just catching up."
        }), _jsx(_components.p, {
          children: "Elettra Brixi was five years dead, an accident in Milan, details never revealed; it tinged the sadness of her passing with intrigue, which of course Elettra would have loved, might even have planned."
        }), _jsx(_components.p, {
          children: "Harriet’s publisher, the extremely literary Fish House, had been bought by a larger company, and that company by another in turn. That was fine, or fine-ish. The problem was: people weren’t buying Harriet’s books anymore. Her new agent blamed disruptive technology, changing habits, broken brains; he was twenty-six years old, but seemed to cosplay as a French fisherman from the 1930s. Harriet wasn’t convinced. She worried her readers were simply dying."
        }), _jsx(_components.p, {
          children: "In New York, for the first time, she checked into a hotel that was not the Conan Arcade. Its Scandinavian gleam unsettled her. When she met her new agent in the lobby, he confessed, over an unpleasant cup of light-roast coffee, that Fish House had declined to purchase her latest manuscript. “They’ve been sucked into the algorithm,” he fumed, staring at his mug. “We’ll take it somewhere else. We’ll take it to a university press!”"
        }), _jsx(_components.p, {
          children: "Harriet consoled him — thinking it ought to be the other way around — and watched him pedal his bicycle unsteadily back toward Brooklyn. She wandered the rest of the day, tried to understand her own feelings. She wasn’t upset, exactly; rather, she was so perplexed that her nervous system understood it as upset. Her novels had gotten consistently better; this new one was her best yet. But it didn’t matter. While she had been typing in her little shed with a view of Lake Superior, the keys on her keyboard had become disconnected from the actuators of the world."
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "The wind was whistling through the matrix of Manhattan. It was ending, all of it."
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "New York no longer required her presence, she realized. The wind was whistling through the matrix of Manhattan. It was ending, all of it. Elettra had gotten out at the right time."
        }), _jsx(_components.p, {
          children: "Harriet’s walk took her threading through Greenwich Village; she had it in her head to see the Conan Arcade again before it fell. She zigzagged toward Midtown, found her course at the Flatiron Building. She took pleasure in her Manhattan orienteering, honed over decades, organized around the hotel in a twenty-block radius. She knew this place, and she knew Lake Superior — how many on the sidewalk beside her could say the same? The truth is: Harriet Amber was arrogant. She had attempted something difficult, and it had worked out perfectly, and people don’t often recover from that."
        }), _jsx(_components.p, {
          children: "Stalking west, she found the Conan Arcade in all its glory, a sagging hulk of gray brick. Before, it had worn a glittering skirt of light, but now the lobby was as dark as the upper stories. The building obviously depended on its neighbors for structural support; Harriet wondered if, when the Conan Arcade came down, they would rebound, Atlases unburdened. She approached the revolving door and gave it a nudge, but it only rattled in place, held fast."
        }), _jsx(_components.p, {
          children: "She stood on the sidewalk peering up at the hotel’s facade. The wind screamed in from the Hudson and peeled the cap off her head, carried it fluttering back toward the Flatiron. She took this as a signal to become unhinged."
        }), _jsx(_components.p, {
          children: "Harriet extracted her phone and called her editor’s office, where an assistant — a young voice she didn’t recognize — patched her through to Jim Picual."
        }), _jsx(_components.p, {
          children: "“I’m sorry, Harriet,” Jim said without preamble. “I fought for it — I made our case — but the P&Ls just don’t look right anymore.”"
        }), _jsx(_components.p, {
          children: "“Then you can make it up to me,” Harriet said coolly."
        }), _jsx(_components.p, {
          children: "“Of course. How? Dinner? Name the place —”"
        }), _jsx(_components.p, {
          children: "She rolled her eyes. Jim Picual was a good editor but a dull dinner date. “No. I need some Fish House magic. I want a room at the Conan Arcade.”"
        }), _jsx(_components.p, {
          children: "“Oh. Huh. That shouldn’t be a problem. ... Uh. The Conan Arcade can’t possibly be booked up, can it?”"
        }), _jsx(_components.p, {
          children: "“The hotel is closed, Jim. They’re going to demolish it. But I want to stay there tonight.”"
        }), _jsx(_components.p, {
          children: "Jim was silent a moment, then he started to laugh. It was not a laugh of mockery or disbelief, but of appreciation, and conspiracy. “Harriet, you’ve never —” He couldn’t continue; was overcome. He tried again: “You’ve never asked for —” He lost it again, and now Harriet was laughing, too, the wind stinging her eyes, tears flooding down her cheeks. “Well, Mr. Fish House?” she cried. “Can you do it?”"
        }), _jsx(_components.p, {
          children: "Her editor composed himself. She could picture him perfectly: he was a ruddy man at rest, so his cheeks would be hot supernovae after a round of laughter. “Ahhh,” Jim breathed. “Oh. As I was saying. Harriet Amber, you’ve never asked for a single fancy thing. Not one ludicrous request. All this time, you were just saving them up.”"
        }), _jsx(_components.hr, {}), _jsx("p", {
          className: "no-indent",
          children: "Jim’s list of authors was willfully eclectic; it included the streetwear impresario Lin Tha Emperor, whose court photographer was known as ARKIV, and also sometimes Nelson Gu. The message went out, Jim to Lin to Nelson, who met Harriet that evening on the sidewalk across from the Conan Arcade."
        }), _jsx(_components.p, {
          children: "She expected a skinny, slippery vampire, but Nelson was her age, maybe older, an amiable pear shape in technical outerwear. She had to remind herself that people had been cool in New York for a very, very long time."
        }), _jsx(_components.p, {
          children: "“This place is dope,” Nelson said, gazing up at the hotel. “I love their lamb burger.”"
        }), _jsx(_components.p, {
          children: "“SO DO I,” Harriet nearly screamed. She remembered this was a stealth operation and lowered her voice. “So do I. I think I would pay a thousand dollars to eat just one more. Room service.”"
        }), _jsx(_components.p, {
          children: "Nelson assessed the scene. “Let’s give it a look.”"
        }), _jsx(_components.p, {
          children: "Harriet carried a tote bag — Fish House’s, from five summers ago — containing a clean shirt, her toothbrush, and a phone charger. She followed Nelson, as casually as possible, into the alley beside the hotel, where a dumpster overflowed with paper, cardboard, and — yes: dining utensils, a pile of them, glinting in the gloom like a dragon’s hoard. A cargo van was parked beside a service door. When Nelson tested the door, it opened."
        }), _jsx(_components.p, {
          children: "“Somebody is here,” he hissed."
        }), _jsx(_components.p, {
          children: "“Oh,” Harriet said. “In that case, maybe I shouldn’t —”"
        }), _jsx(_components.p, {
          children: "Nelson looked at her in disbelief. “They’re just cleaning up. You want to stay here? No one’s going to stop you.”"
        }), _jsx(_components.p, {
          children: "Inside, a shadowed corridor brought them to a door that opened behind the front desk. After twenty years of check-ins, this sudden reversal of perspective was dizzying. They rounded the desk, padded into the dark lobby; Nelson produced a flashlight from an ostentatiously placed pocket, and its beam nuzzled the space’s edges. Everything sat just as in Harriet’s memory, not a single rug or mirror out of place — and there were a LOT of rugs and mirrors. The hard-edged glare of the flashlight made her think of that photo shoot in the magazine so many years ago."
        }), _jsx(_components.p, {
          children: "Nelson offered a hand for her tote bag. “No, it’s fine,” Harriet began, but he said, “Come on. Let’s do it right.” He cleared his throat and intoned: “MA’AM, MAY I SHOW YOU TO YOUR ROOM?”"
        })]
      }), "\n", _jsx(Image, {
        src: one,
        alt: "Generative illustration of a hotel room with wood-veneer furniture and intensely floral-patterned bedspread and wallpaper."
      }), "\n", _jsx(_components.hr, {}), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "The elevator, though a tomb of darkness lit only by the tiny golden discs of the buttons, still ran. On the seventh floor, they disembarked, followed the beam of Nelson’s flashlight to Harriet’s customary room. The Conan Arcade had, finally but reluctantly, upgraded to key card readers, and 707’s held the door fast. Harriet wasn’t surprised when Nelson pulled out a slim dongle which, plugged into his phone, wrung out of the key card reader first a chittering howl of distress, then a click of surrender."
        }), _jsx(_components.p, {
          children: "Inside, Harriet found the lamp beside the bed, summoned its sickly incandescent glow, perfection. Everything was exactly as she expected. There were sheets on the bed. It was ghostly; would they really demolish a hotel with sheets still on the beds?"
        }), _jsx(_components.p, {
          children: "“Can I take your picture?” Nelson asked. Harriet turned, put on the kind of bored expression she remembered from the magazine photo shoot. Nelson clicked a few frames with his camera, which was as slender and shiny as a beetle."
        }), _jsx(_components.p, {
          children: "He lowered the camera. “This is your usual room?”"
        }), _jsx(_components.p, {
          children: "“Oh, yes,” Harriet said. “I could draw this wallpaper from memory.” When she raised a hand to touch the eye-popping pattern, Nelson’s camera clicked again. He had lured her into a better pose."
        }), _jsx(_components.p, {
          children: "“You going to be okay here?” he asked. “I can — uh. I don’t know. I could hang out in a room down the hall.”"
        }), _jsx(_components.p, {
          children: "Harriet surprised herself by declining his offer. The reasonable part of her wanted a friendly face nearby, especially in case someone came through to check the rooms. Another part of her, the part that had been revealed when her hat was swept away, wanted to be alone in this empty hulk."
        }), _jsx(_components.p, {
          children: "Yet another part, the least reasonable of all, hoped the hotel would collapse while she slept. No reason for Nelson Gu to be crushed along with her."
        }), _jsx(_components.hr, {}), _jsx("p", {
          className: "no-indent",
          children: "She paced the room and listened to the traffic through the open window. Yes: open. This was the great gift of the Conan Arcade, an evasion of whatever safety code now decreed that all hotel rooms had to suffocate you. The other hotel, sleek and Scandinavian, betrayed itself with windows that opened a pathetic millimeter. Here in the Conan Arcade, you could rip the window wide open and feel the wind sheeting off the Hudson, hear the honks and sirens from the street at full volume, and, if you chose, throw yourself to the pavement below."
        }), _jsx(_components.p, {
          children: "This was Harriet’s final stay in this room, and in this moment, it felt just like all her other stays. Always the same ritual: take off her shoes (she did that now) and flop onto the bed (she did that now) and listen to the song of the city (she did that now) and then pick up the phone handset, press the button for room service, order the lamb burger, her favorite."
        }), _jsx(_components.p, {
          children: "She did that, too, and, to her surprise, someone answered."
        }), _jsx(_components.p, {
          children: "“Who the hell is calling?” the someone said."
        }), _jsx(_components.p, {
          children: "Hell? No — heaven. Harriet Amber had stepped into a dream world. Everything was the same, and maybe she could stay here forever."
        }), _jsx(_components.p, {
          children: "“It’s room 707,” she said lightly. “I’d like to order a lamb burger and a glass of red wine.”"
        }), _jsx(_components.p, {
          children: "The someone on the other end laughed, just the way Jim Picual had laughed. “Lady — room 707, what? Is this a joke?”"
        }), _jsx(_components.p, {
          children: "“I’ve stayed in this room for years, and I’m staying here tonight. How about that burger?”"
        }), _jsx(_components.p, {
          children: "“You’re staying here tonight — you’re. Huh. Well.” The person on the other end tsked rapidly. Tsks like a ticking clock, or the timer on a toaster oven."
        }), _jsx(_components.p, {
          children: "“Okay, listen,” dinged the voice on the other end. “I’m not exactly equipped down here. I don’t know if I can do the lamb burger. But I’ll scrounge something up.”"
        }), _jsx(_components.hr, {}), _jsx("p", {
          className: "no-indent",
          children: "One hour passed, then another. Harriet twice lifted the phone handset, hovered her finger over the room service key, but didn’t press it, or her luck. Instead, she thought about how strange it was to hold a phone handset, the kind with a long curling cord. Who did that anymore, except in a hotel room, ordering room service?"
        }), _jsx(_components.p, {
          children: "She heard the phone ring in the room next door; it made her stomach flip. If she heard it answered, heard a voice murmur “yeees?”, a voice that had been there, on the other side of the wall, all along, she would dash down the hallway in the opposite direction, or maybe out the window after all — but there was no answer. The phone rang three times, and no more."
        }), _jsx(_components.p, {
          children: "Then her phone rang. There was, she estimated, an even chance it was some stern hotel overseer calling to demand she depart, or the someone from below inquiring if she wanted her burger rare."
        }), _jsx(_components.p, {
          children: "She did want that burger rare, so she picked up the phone."
        }), _jsx(_components.p, {
          children: "“707, right. It’s ready,” the someone said, “but, can you come down to the kitchen, please? I don’t think it’s a good idea for me to bring it up there.”"
        }), _jsx(_components.p, {
          children: "“What? Why not?” Harriet asked."
        }), _jsx(_components.p, {
          children: "The someone tsked. “I don’t know. It’s creepy. What if you’re a murderer?”"
        }), _jsx(_components.p, {
          children: "“What if YOU’RE a murderer?”"
        }), _jsx(_components.p, {
          children: "“I’m not a — listen. I am right now telling you that I am not going to ride the elevator in the dark to some room on the seventh floor. You think I usually deliver these things? However, I am ALSO telling you that I have a lamb burger down here. So. You can have it if you want it.”"
        }), _jsx(_components.p, {
          children: "Harriet Amber pulled on her shoes and, waving her phone as a tepid flashlight, made her way to the elevator."
        })]
      }), "\n", _jsx(Image, {
        src: two,
        alt: "Plated lamb burgers sitting on a tabletop, with tomatoes in the background."
      }), "\n", _jsx(_components.hr, {}), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "The kitchen in the belly of the Conan Arcade sparkled in a way no other part of the hotel even attempted; it gleamed, redolent of lemon-scented cleaner. Plastic tubs were stacked inside other plastic tubs, and pots and colanders piled into cardboard boxes newly assembled. Not everything in this hotel was as valueless as the sheets."
        }), _jsx(_components.p, {
          children: "There, Harriet met the someone on the other end of the phone: a woman, possibly her age; it was hard to say. She was tiny."
        }), _jsx(_components.p, {
          children: "“I cooked it rare,” the chef boomed, “because I like it rare, and if I’m going to cook the last lamb burger in this place, it’s going to be rare.” Her voice didn’t quite match her body."
        }), _jsx(_components.p, {
          children: "In open defiance of the chef’s brusqueness, the burger was presented in a perfect place setting, white plate alongside napkin and knife and fork, and a glass of wine, light red. The woman — the chef — had a glass of her own."
        }), _jsx(_components.p, {
          children: "“So,” said the chef."
        }), _jsx(_components.p, {
          children: "“So,” said Harriet Amber. The chef was difficult to figure out. She had a webbing of fine lines around her eyes and very prominent biceps."
        }), _jsx(_components.p, {
          children: "They looked at each other, and Harriet relented. “I’ve been staying here since — oh, for twenty years. I’ve never stayed anywhere else in the city. And I just got some bad news today — shocking, actually — so I decided I want to stay here again. My name’s Harriet, by the way.”"
        }), _jsx(_components.p, {
          children: "The chef sniffed. “Twenty years, huh? I’ve been feeding you this whole time, if you ordered room service.”"
        }), _jsx(_components.p, {
          children: "“I always order room service. The lamb burger.”"
        }), _jsx(_components.p, {
          children: "“Yeah, well, it’s a good fucking burger. Go ahead! Eat!”"
        }), _jsx(_components.p, {
          children: "Harriet obeyed, and, chewing her first bite, she nodded in agreement so deep it was almost a bow. Her body confirmed: it was a good fucking burger. When she had chewed and swallowed, she spoke. “Are you sad about it? The hotel closing?”"
        }), _jsx(_components.p, {
          children: "“Sure. I love it down here. It always felt like ... I don’t know, you see those movies with submarines, or spaceships, and there’s always the guy in the engine room, he’s screaming and running around and there’s steam everywhere, or ... nuclear gas, whatever ... but he always makes it work, and he’s the reason it all works, right? I’m that guy.”"
        }), _jsx(_components.p, {
          children: "Harriet took another bite and nodded again. The chef was most definitely that guy."
        }), _jsx(_components.p, {
          children: "“What’s the secret?” Harriet asked. “Why is your burger so good?”"
        }), _jsx(_components.p, {
          children: "“It’s not,” the chef cackled. “What can I tell you? It’s lamb. There’s a bit of beef mixed in, too. Piedmontese. The sauce is fancy. It just grew this LEGEND, and if people expect something to be legendary, it only has to be good. And, yeah, I can make a good burger.”"
        }), _jsx(_components.p, {
          children: "Harriet startled. “That’s how I wrote my books, too,” she said — not realizing it until that moment. “But the legend ran out.”"
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "“If people expect something to be legendary, it only has to be good.”"
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "“Always does,” said the chef. “I felt it coming, a few years ago. Just ... I felt it.” She eyed Harriet. “A writer, huh? That’s the other thing. Everybody up there — I knew it was you authors and musicians. Poets, painters, pornographers, ha! So ... have I heard of you?”"
        }), _jsx(_components.p, {
          children: "Harriet Amber said her name, and the titles of her books, and was not surprised by the blank reply on her interlocutor’s face. “Ah ... cool,” the chef said unconvincingly. “Yeah. Michigan, wow. Well, I’m Chef Tonia.”"
        }), _jsx(_components.p, {
          children: "Chef Tonia, Harriet thought: down here all this time. Author of her comfort."
        }), _jsx(_components.p, {
          children: "“What will you do next?” Harriet asked."
        }), _jsx(_components.p, {
          children: "“I don’t know,” Chef Tonia said. “I could run another kitchen, but I’ve been pretty burned out for a while. It’s not an emergency; I’ve got some savings. I’ll figure something out eventually. In the meantime, I’m keeping these pans.”"
        }), _jsx(_components.p, {
          children: "Chef Tonia returned to her boxes. Harriet finished her burger and sipped her wine and, when she was finished, said thank you, and announced she was off to bed."
        }), _jsx(_components.p, {
          children: "The chef sputtered. “Lady, this is IT. Literally the last night. They’re going to knock this building down tomorrow. If you sleep in —”"
        }), _jsx(_components.p, {
          children: "“I never sleep in,” Harriet said."
        }), _jsx(_components.p, {
          children: "Chef Tonia tsked dangerously. “I will not let you go back up to that stupid room. Here.” The chef refilled her glass. “Help me pack. Or sit there. I don’t care. Tell me about ... Michigan? I barely know where that is.”"
        })]
      }), "\n", _jsx(_components.hr, {}), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "Sometime after midnight, Chef Tonia deposited a very drunk Harriet Amber at the threshold of her Scandinavian hotel, stalking around to open the door and help her out of the cargo van that held the remnants of the Conan Arcade’s kitchen."
        }), _jsx(_components.p, {
          children: "“Thank you for all those meals, over all those years,” Harriet said. “I’m going to miss that hotel.”"
        }), _jsx(_components.p, {
          children: "“No reason to miss it,” Chef Tonia sniffed. “I’ll make you a burger anytime you want. Give me your phone.” She tsked decisively, tapped her number, pushed the phone back into Harriet’s hands, then leapt, as if fleeing, into the cargo van, and was off."
        }), _jsx(_components.p, {
          children: "That night, Harriet slept unhappily below a window opened only a millimeter to the miracle of Manhattan. In the morning, across the city, a line of explosive charges detonated around the skirt of the Conan Arcade, and the hotel came down, sheets and all."
        }), _jsx(_components.p, {
          children: "After a cortado and an almond croissant, Harriet sat in the Scandinavian hotel’s lobby, composing a “pitch” — she could only think of the word in quotation marks — to her new agent. At noon, she sent the email, then caught a taxi to the airport."
        }), _jsx(_components.p, {
          children: "A year later, in the lot where the Conan Arcade once stood, the slab of condos had risen as planned, and one of them belonged to Harriet Amber. The move was, she realized, long overdue. She would live in New York, like approximately every other person on the planet. The sacrifice of the thing that made her special, her icy provincial insight, was easier now, because nobody cared about it anymore."
        }), _jsxs(_components.p, {
          children: ["Chef Tonia lived half a block away; the circumference of her life had always been Manhattan-tight. Tonia and Harriet met often for drinks, and sometimes Tonia cooked her dinner, and sometimes Harriet admired her biceps. The cookbook they coauthored, ", _jsx("cite", {
            children: "The Kitchen at the Conan Arcade"
          }), ", was a hit, surprising everyone except Harriet, who felt Elettra moving within her: her sagacity, her certainty. Fish House had never published a cookbook before; Jim Picual was hailed a pioneer. Harriet would still not dine with him."]
        }), _jsx(_components.p, {
          children: "For the cookbook, she licensed the images from that ancient photo shoot, which had been captured, of course, by a much younger Nelson Gu. She hired him to photograph Chef Tonia’s dishes, too, so theirs was perhaps the only cookbook ever to depict a lamb burger in harsh, rockstar monochrome. A little bit unhinged, sure — and it worked."
        }), _jsx(_components.p, {
          children: "At night, Harriet paced back and forth in her condo, thinking about the loops of time and fate, about death in Milan, about ice on the lake. She thought she might try writing a thriller. The window was wide open, and the song of the street bellowed."
        }), _jsx(_components.p, {
          children: "She wondered where she could get a lamb burger at this hour."
        }), _jsxs("p", {
          className: "bio",
          children: [_jsx("b", {
            children: _jsx(_components.a, {
              href: "https://www.robinsloan.com/",
              children: "Robin Sloan"
            })
          }), " is the author of ", _jsx("cite", {
            children: _jsx(_components.a, {
              href: "https://www.robinsloan.com/books/sourdough",
              children: "Sourdough"
            })
          }), " and ", _jsx("cite", {
            children: _jsx(_components.a, {
              href: "https://www.robinsloan.com/books/penumbra",
              children: "Mr. Penumbra’s 24-Hour Bookstore"
            })
          }), "."]
        })]
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
