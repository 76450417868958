/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import cover from "/public/images/la-morada_r3_v1-04.png";
export const meta = {
  title: "Food as Resistance at La&nbsp;Morada",
  author: "Daniel Pravit Fethke",
  position: 5,
  date: "2021-12-08T10:35:57+0000",
  slug: "food-as-resistance",
  category: "News",
  type: "local-news-article",
  paragraphs: "spacing",
  byline_family: 3,
  pulled_quotes: false,
  cover: {
    src: cover,
    static: cover,
    alt: "Abstract round plate of food in browns and greens overlaying a grid of black-and-white cityscapes.",
    credit: "Derrick Schultz",
    position: "before"
  },
  excerpt: "Community care in the&nbsp;Bronx.",
  layouts: {
    "Antique No 6": {
      title: "Food as Resistance at&nbsp;La&nbsp;Morada"
    },
    "Original Sans": {
      title: "Food as <br /><b>Resistance</b><br/> at La Morada"
    },
    "Le Jeune": {
      title: "Food as Resistance at <b>La Morada</b>",
      author: "Daniel \nPravit \nFethke"
    },
    "Lyon": {
      excerpt: "“We needed to give back, because otherwise we would be complicit in the gentrification that’s going on everywhere in the city.”"
    },
    "Eugenia": {
      title: "Food as Resistance at La Morada, a news article by Daniel Pravit&nbsp;Fethke"
    }
  },
  weights: {
    text: 400,
    hed: 600
  },
  families: {
    text: 1,
    headings: 2,
    pullquotes: 2,
    byline: 3,
    bio: 3
  },
  cssSelectors: [{
    selector: ".body p",
    output: "body"
  }, {
    selector: ".article-title",
    output: "h1"
  }, {
    selector: ".byline",
    output: "header address",
    excludeFromCSS: true
  }, {
    selector: "blockquote p",
    output: "blockquote"
  }, {
    selector: ".bio",
    output: "footer",
    excludeFromCSS: true
  }, {
    selector: ".bio :is(strong, b)",
    output: "footer",
    excludeFromCSS: true
  }],
  pairings: {
    "Guardian Egyptian Text {1.4}": [["Guardian Sans Headline <1.0357> {1.21}", "Guardian Sans Text {1.4}"], ["Guardian Egyptian Headline <1.0357> {1.21}", "Guardian Sans Text {1.4}"], ["Action Condensed Regular <1.7143> {1}", "Action Text Bright {1.4}"], ["Sanomat Sans <1.0714> {1.15}", "Sanomat Sans Text {1.4}"], ["Publico Headline {1.2}", "Guardian Sans Text {1.4}"], ["Styrene B <0.9286> {1.15} [500]", "Styrene B {1.4}"], ["Graphik {1.185}", "Graphik <0.9375> {1.4}"], ["Sanomat {1.21}", "Sanomat Sans Text {1.4}"]],
    "Austin News Text {1.4}": [["Action Condensed Regular <1.7143> {1}", "Action Text Dark {1.4}"], ["Austin News Deck {1.15}", "Austin News Text {1.4}"], ["Sanomat Sans <1.0714> {1.15}", "Sanomat Sans Text {1.4}"], ["Action Text Dark {1.21}", "Action Text Dark {1.4}"], ["Canela Deck {1.2}", "Graphik <0.9375> {1.4}"], ["Caslon Doric <0.9643> {1.23}", "Caslon Doric <1.0625> {1.4}"], ["Caslon Ionic <0.9643> {1.19}", "Antique No 6 {1.4}"], ["Antique No 6 <0.9643> {1.19}", "Antique No 6 {1.4}"], ["Duplicate Sans <1.1429> {1.1}", "Duplicate Sans <1.1250> {1.4}"], ["Duplicate Slab <1.1429> {1.1}", "Duplicate Slab <1.1250> {1.4}"], ["Eugenio Sans <1.15> {1.135}", "Eugenio Sans Text <1.0625> {1.4}"], ["Graphik {1.185}", "Graphik <0.9375> {1.4}"], ["Graphik Condensed <1.1429> {1.16}", "Graphik Compact <1.0625> {1.4}"], ["Sanomat {1.21}", "Sanomat Sans Text {1.4}"], ["Styrene B <0.9286> {1.15}", "Styrene B {1.4}"]],
    "Guardian Sans Text {1.4}": [["Guardian Sans Headline Condensed <1.1429> {1.16}", "Guardian Sans Text {1.4}"], ["Guardian Sans Headline <1.0357> {1.16}", "Guardian Sans Text {1.4}"], ["Guardian Egyptian Headline <1.0357> {1.2}", "Guardian Sans Text {1.4}"], ["Action Condensed Medium <1.7143> {1}", "Guardian Sans Text {1.4}"], ["Austin News Deck {1.15} [500]", "Austin News Text {1.4}"], ["Sanomat {1.21}", "Guardian Sans Text {1.4}"], ["Publico Headline <1.1> {1.2}", "Publico Text <1.0625> {1.4}"], ["Frame Head <0.9286> {1.28}", "Frame Text {1.4}"], ["Feature Deck <0.9286> {1.28}", "Guardian Sans Text {1.4}"]],
    "Publico Text <1.0625> {1.4}": [["Eugenio Sans {1.135}", "Eugenio Sans Text <1.0625> {1.4}"], ["Publico Headline {1.2} [400]", "Publico Text <1.0625> {1.4}"], ["Guardian Egyptian Headline <1.0357> {1.15}", "Guardian Sans Text {1.4}"], ["Guardian Sans Headline <1.0357> {1.15}", "Guardian Sans Text {1.4}"], ["Sanomat {1.21}", "Sanomat Sans Text {1.4}"], ["Sanomat Sans {1.2}", "Sanomat Sans Text {1.4}"], ["Action Text Dark {1.2}", "Action Text Bright {1.4}"], ["Graphik {1.185}", "Graphik <0.9375> {1.4}"], ["Graphik Condensed <1.1429> {1.16}", "Graphik Compact <1.0625> {1.4}"], ["Algebra Display <1.1> {1.21}", "Publico Text <1.0625> {1.4}"], ["Antique No 6 <0.9643> {1.19}", "Caslon Doric <1.0625> {1.4}"], ["Duplicate Ionic <1.15> {1.1}", "Duplicate Sans <1.1250> {1.4}"], ["Lyon Display <1.0357> {1.21} [400]", "Graphik <0.9375> {1.4}"], ["Marr Sans <1.0357> {1.175}", "Marr Sans <1.0625> {1.4}"], ["Marr Sans Condensed <1.25> {1.16}", "Marr Sans <1.0625> {1.4}"]],
    "Atlas Grotesk {1.4}": [["Lyon Display <1.0357> {1.21}", "Lyon Text <1.0625> {1.4}"], ["Eugenio Serif <1.1071> {1.15} [300]", "Atlas Grotesk {1.4}"], ["Atlas Grotesk {1.225}", "Atlas Grotesk {1.4}"], ["Publico Headline {1.2}", "Atlas Grotesk {1.4}"], ["Algebra <1.0357> {1.21}", "Algebra {1.3}"], ["Feature Deck <0.9286> {1.28}", "Feature Text {1.4}"], ["Austin News Deck {1.15} [400]", "Austin News Text {1.4}"], ["Frame Head <0.9286> {1.28}", "Atlas Grotesk {1.4}"], ["Caslon Ionic <0.9643> {1.19} [400]", "Atlas Grotesk {1.4}"], ["Brunel Deck <1.1> {1.15}", "Brunel Text Short <1.1250> {1.32}"], ["Caponi Display <1.1071> {1.1}", "Atlas Grotesk {1.4}"], ["Darby Serif Display <1.1> {1.15}", "Darby Serif Text {1.4}"], ["Le Jeune Deck <1.0357> {1.21}", "Atlas Grotesk {1.4}"], ["Terza Reader {1.275}", "Terza Reader <0.9375> {1.38}"]],
    "Produkt <1.0625> {1.4}": [["Robinson <1.1> {1.2} [400]", "Produkt <1.0625> {1.4}"], ["Produkt <0.9643> {1.21}", "Produkt <1.0625> {1.4}"], ["Graphik {1.185}", "Graphik <0.9375> {1.4}"], ["Graphik Condensed <1.1429> {1.16}", "Graphik <0.9375> {1.4}"], ["Sanomat Sans <1.0714> {1.15}", "Sanomat Sans Text {1.4}"], ["Terza Reader <0.9286> {1.275}", "Terza Reader <0.9375> {1.38}"], ["Eugenio Serif <1.25> {1.2} [400]", "Produkt <1.0625> {1.4}"]]
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p",
      a: "a",
      em: "em",
      blockquote: "blockquote"
    }, props.components), {FontSection} = _components;
    if (!FontSection) _missingMdxReference("FontSection", true);
    return _jsxs(_Fragment, {
      children: [_jsxs(FontSection, {
        children: [_jsxs(_components.p, {
          children: ["New York’s restaurants have a lot to complain about. With inflation at an all-time high, gentrification causing rent prices to soar, and the ", _jsx("i", {
            children: "n"
          }), _jsx("sup", {
            children: "th"
          }), " wave of the COVID-19 virus arriving on the East Coast, it’s no wonder restaurants are struggling. Almost two full years into a global pandemic that has rocked New York to its core, the industry still finds itself scrambling to keep up."]
        }), _jsxs(_components.p, {
          children: ["And then there’s ", _jsx(_components.a, {
            href: "https://lamorada.nyc",
            children: "La Morada"
          }), ", a family-run Mexican restaurant in the Mott Haven neighborhood of the Bronx. Established in 2009 by Natalia Mendez and Antonio Saavedra as a traditional ", _jsx("i", {
            children: "cocina oaxaqueña"
          }), ", La Morada has consistently been a neighborhood beacon of hope amid so much uncertainty."]
        }), _jsxs(_components.p, {
          children: ["Even before entering the restaurant, visitors are confronted with a massive sidewalk mural reading ", _jsx(_components.em, {
            children: "Abolish / Police / Migra."
          }), " A sign on the front door reads ", _jsx(_components.em, {
            children: "Refugees Welcome"
          }), ", painted in bright red block capitals — a nod to La Morada’s place in the neighborhood as a sanctuary for the undocumented. When you enter the royal-purple interior, the frenzy of activity makes it immediately clear that this is more than your typical Mexican restaurant."]
        }), _jsxs(_components.p, {
          children: ["That’s not to say that food is not important to the Saavedra family. From the six kinds of simmering moles that perfume the air with their delicate notes of chocolate and chile, to the handmade tortillas crafted with the utmost care, to the fresh tlacoyo corn cakes topped with nopal cactus salad, it’s clear that Oaxacan food is taken very seriously here. Carolina, the youngest of the three Saavedra siblings and previously a contestant on Food Network’s ", _jsx("cite", {
            children: "Chopped"
          }), ", even works concurrently as an educator for the Michelin-starred Stone Barns Center, where she leads workshops on Indigenous cuisine."]
        }), _jsx(_components.p, {
          children: "But all of this revolutionary gastronomy was upended in March 2020. COVID hit the Bronx hard, and Mott Haven even harder. The neighborhood’s proximity to the serpentine Major Deegan Expressway produces higher than average asthma rates, compounding the effects of a respiratory virus in an already marginalized area. Like most New Yorkers, the Saavedras quickly shuttered their restaurant, hoping to weather the storm together as a family."
        }), _jsx(_components.p, {
          children: "They couldn’t sit still for long. After a few weeks of quarantining, La Morada reopened its doors to its neighbors, handing out free chicken soup to a line that stretched around the block. As Marco, the middle child, explained to me when I interviewed him at the restaurant in November: “We have always wanted to give back, whether that be through fundraisers, community events, or disseminating information. I feel like the mutual aid was just an organic result of [our] growth.”"
        }), _jsx(_components.p, {
          children: "This elegantly selfless act of giving out food to neighbors started a tidal wave of mutual aid that has come to redefine La Morada. From their first day serving chicken soup, the need for food only grew. In response, the Saavedra family transformed their beloved restaurant into a community soup kitchen, pushing aside the dining tables and enlisting the help of neighborhood volunteers. “We’ve always been about being aware of who we are in our community,” Marco told me, “and after being welcomed for over a decade, [we needed] to give back because otherwise we would be complicit in the gentrification that’s going on everywhere in the city, [especially] in Mott Haven.”"
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "“We needed to give back, because otherwise we would be complicit in the gentrification that’s going on everywhere in the city.”"
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsxs(_components.p, {
          children: ["And so the momentum continued. A family friend set up a GoFundMe account for the restaurant, raising an initial $45,000. As a result, the Saavedras were able to distribute 7,800 free meals to neighbors during April 2020. The next month, that number climbed to 25,200 meals — a staggering amount of food that solidified La Morada as part of the bedrock of the Mott Haven community. Since those first few months, the restaurant has distributed on average ", _jsx(_components.a, {
            href: "https://archivesincommon.com/mutual-aid/mutual-aid-visualization",
            children: "almost five hundred meals per day"
          }), "."]
        }), _jsx(_components.p, {
          children: "As La Morada’s soup kitchen efforts grew, so too did the means to distribute that food. From deliveries to NYCHA houses, to neighbor-to-neighbor support networks, to filling up the community fridges that popped up outside of bodegas, their food found its way into every corner of Mott Haven’s community networks."
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "“I think it also helped during the pandemic that there was a shift in values.”"
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx(_components.p, {
          children: "“We’re so used to the capitalistic model of people eating what they can afford, versus mutual aid is more like you’re basically feeding people based on their need, which is much more humane, but it’s also what the industry is not used to,” Marco said, referring to the growing pains of shifting away from a conventional restaurant model. “I think it also helped during the pandemic that there was a shift in values.”"
        }), _jsx(_components.p, {
          children: "The initial fundraising momentum only got them so far, though. Faced with the sheer cost of producing so much food, Yajaira, the eldest sister, began applying for all manner of grants, including the federal PPP loans. “We were able to piece smaller grants together,” says Marco of his family’s first attempts to apply for funding. “Not any of the federal stuff just because we’re undocumented.”"
        }), _jsxs(_components.p, {
          children: ["Since moving to New York in the early 1990s, the Saavedras have run up against their fair share of immigration obstacles: Marco has ", _jsx(_components.a, {
            href: "https://www.democracynow.org/2021/2/25/headlines/immigration_activist_marco_saavedra_wins_political_asylum",
            children: "filed for asylum as an act of protest"
          }), "; ", _jsx(_components.a, {
            href: "https://www.nydailynews.com/new-york/nyc-crime/ny-metro-bronx-restauranteur-activist-immigrants-busted-harassment-nypd-20190206-story.html",
            children: "Yajaira was arrested"
          }), " inside the restaurant for filming undercover police officers. Needless to say, La Morada hit a dead end when applying for federal funding during the pandemic."]
        }), _jsx(_components.p, {
          children: "Luckily, they were able to piece together a patchwork of donations, finding support from sources ranging from private donors to academic grants. “People would tell us, ‘Oh, there’s this grant that’s coming up, why don’t you apply for this?’” Marco recalled. Today, the restaurant is regularly abuzz with a network of students and professors from CUNY and The New School. This academic community has come together to peel potatoes and write grants, helping the Saavedras persist despite the challenging economics of running a soup kitchen. “It’s been a lot of work, but we’ve had so much help to navigate it, thankfully,” Marco said."
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "“It’s been a lot of work, but we’ve had so much help to navigate it, thankfully.”"
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsxs(_components.p, {
          children: ["The same puzzle-piece methodology goes for the ways in which the restaurant is able to source food. One day will bring in-kind donations from local butchers; the next, a large haul of produce from farmers upstate. This reliance on donations (known as ", _jsx("i", {
            children: "dispensas"
          }), " or ", _jsx("i", {
            children: "compras)"
          }), ", in combination with their patchwork of community funding, has enabled La Morada to run their mutual aid operations from the very beginning."]
        }), _jsx(_components.p, {
          children: "Perhaps the most beautiful part of all is the way the Saavedras have been able to weather the past two years as a family. “You are going to get mad at each other, right?” said Marco. “Schedules are going to interfere and it’s going to feel overwhelming. It’s very despairing and hopeful at the same time, right? That you see how much need there is.”"
        }), _jsx(_components.p, {
          children: "Far from being an easy experience, they have found a balance within themselves that keeps them going day after day. “You know, there’s some days that you just don’t want to get out of bed, or you just just feel so overwhelmed or stressed,” Marco said. “But my mom and my dad and my sister are also getting ready for the day, and are already in the car so that we can drive down to open up the restaurant. So, that is enough motivation for me.”"
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs("p", {
          className: "bio",
          children: [_jsx("b", {
            children: _jsx(_components.a, {
              href: "https://danfethke.com",
              children: "Daniel Fethke"
            })
          }), " is a filmmaker, artist, and activist based in Brooklyn. He is one half of ", _jsx("b", {
            children: _jsx(_components.a, {
              href: "https://angrypapaya.cargo.site/ABOUT",
              children: "Angry Papaya"
            })
          }), ", a nomadic pop-up that serves Thai-Chinese cooking on a sliding scale for neighbors in New York City."]
        })
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
