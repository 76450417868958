import Head from "next/head"
import { getPageTitle, removeHTMLEntities } from "../utils/html"

export type MetadataType = {
  articleMetadata?: any
}
export const Metadata = ({ articleMetadata }: MetadataType) => {
  const generateJSONLD = () => {
    const baseJSON = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@id": "#issue",
          "@type": "PublicationIssue",
          issueNumber: "1",
          description: "",
          datePublished: "2022",
          name: "The Food Issue",
          publisher: {
            "@type": "Organization",
            name: "Commercial Type",
            url: "https://commercialtype.com",
          },
        },
      ],
    }
    if (articleMetadata) {
      const articleJSON = {
        "@type": "Article",
        isPartOf: "#issue",
        ...articleMetadata.jsonLD,
      }
      articleJSON.headline ??= removeHTMLEntities(articleMetadata?.title)
      articleJSON.image ??= [
        { "@type": "ImageObject", url: articleMetadata?.cover?.src?.src },
      ]
      articleJSON.datePublished ??= articleMetadata?.date
      articleJSON.author ??= {
        "@type": "Person",
        name: articleMetadata?.author,
      }
      articleJSON.description ??= articleMetadata?.excerpt

      baseJSON["@graph"].push(articleJSON)
    }
    return baseJSON
  }
  const jsonLD = generateJSONLD()

  return (
    <>
      <Head>
        <title>{getPageTitle(articleMetadata?.title)}</title>
        <meta
          name="description"
          content={
            jsonLD["@graph"][1]?.description || jsonLD["@graph"][0]?.description
          }
        />
        <meta name="og:image" content={jsonLD["@graph"][1]?.image?.[0]?.url} />
      </Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }}
      />
    </>
  )
}
