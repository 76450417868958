import SVG from "react-inlinesvg";
import classNames from "classnames";
import useSWR from "swr";

export const fetcher = (...args) => fetch(...args).then((res) => res.json())

export function LinkedFamilyName({ family }: { family: string }): JSX.Element {
  const params = new URLSearchParams();
  params.append("families", family);
  const url = `/api/cart?${params.toString()}`;
  const { data, error } = useSWR(url, fetcher);

  return (
    <div className="LinkedFamilyName">
      <span>{family}</span>
      <a
        href={data && data.find((d) => d.family === family)?.url}
        className={classNames("LinkedFamilyName__external", {
          isResolved: Boolean(data && data.find((d) => d.family === family)?.url),
        })}
        target="_blank"
        rel="noreferrer"
        title="View family on Commercial Type"
      >
        <SVG src="/icons/external.svg" role="presentation" />
      </a>
    </div>
  );
}
