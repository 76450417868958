/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import cover from "/public/images/beef-patty_r1.png";
export const meta = {
  title: "This Is Love: A&nbsp;Riff on the Jamaican&nbsp;Patty",
  author: "Diane Chang",
  position: 4,
  slug: "this-is-love-a-riff-on-the-jamaican-patty",
  category: "Cooking",
  type: "recipe",
  paragraphs: "indents",
  byline_family: 3,
  pulled_quotes: true,
  cover: {
    src: cover,
    static: cover,
    alt: "Crumbly Jamaican patties split roughly into four pieces against a vivid abstract background in green, yellow, black, and red.",
    credit: "Di-Andre Caprice Davis & Derrick Schultz",
    position: "after"
  },
  excerpt: "Then the internet changed everything.",
  weights: {
    text: 400,
    hed: 600
  },
  families: {
    text: 1,
    headings: 2,
    pullquotes: 2,
    byline: 3,
    bio: 1,
    ingredients: 3
  },
  layouts: {
    "Antique No 6": {
      title: "This Is Love: <br/>A&nbsp;Riff on the Jamaican&nbsp;Patty"
    },
    "Graphik": {
      title: "This Is Love: A&nbsp;Riff on the Jamaican Patty"
    },
    "Original Sans": {
      title: "This Is Love: <br/>A&nbsp;Riff on the <br /><b>Jamaican</b><br /> Patty"
    },
    "Le Jeune": {
      title: "This Is Love: A&nbsp;Riff on the <b>Jamaican Patty</b>",
      author: "Diane \nChang"
    },
    "Lyon": {
      excerpt: "“Biting into the bread, chewing through the crispy pastry crust, and landing on the spiced ground beef filling was a transformative sequence.”"
    },
    "Eugenia": {
      title: "This is Love: a&nbsp;Riff on the Jamaican Patty by Diane&nbsp;Chang"
    },
    "Proxy": {
      title: "This is Love: a&nbsp;Riff \non the Jamaican Patty"
    }
  },
  cssSelectors: [{
    selector: ".body p",
    output: "body"
  }, {
    selector: ".article-title",
    output: "h1"
  }, {
    selector: "h2",
    output: "h2"
  }, {
    selector: "h3",
    output: "h3"
  }, {
    selector: "ul",
    output: ".ingredients-list"
  }, {
    selector: ".instructions li::before",
    output: "ol li::before",
    excludeFromCSS: true
  }, {
    selector: "p :is(b, strong)",
    output: "strong",
    excludeFromCSS: true
  }, {
    selector: "p :is(i, em, cite)",
    output: "em",
    excludeFromCSS: true
  }],
  pairings: {
    "Feature Text {1.4}": [["Review {1.21} [700]", "Review <0.9375> {1.4} [400]"], ["Review Condensed <1.2857> {1.1}", "Review <0.9375> {1.4}"], ["Feature Deck {1.28}", "Review <0.9375> {1.4}"], ["Caslon Ionic <0.9643> {1.19}", "Caslon Doric {1.4}"], ["Canela Deck {1.29} <1.1>", "Feature Text {1.4}"]],
    "Graphik <0.9375> {1.4}": [["Graphik {1.135} [900]", "Graphik <0.9375>  {1.4}"], ["Graphik Condensed <1.1429> {1.09}", "Graphik Compact <1.0625> {1.4}"], ["Styrene B <0.9286> {1.15}", "Graphik <0.9375> {1.4}"], ["Produkt <0.9286> {1.15}", "Produkt <0.9375> {1.4}"], ["Algebra Display <1.0357> {1.21}", "Algebra {1.4}"], ["Caponi Display <1.1071> {1.1}", "Caponi Text <1.1> {1.4} (italic)"], ["Caponi Slab <1.1071> {1.1}", "Caponi Text <1.1> {1.4}"]],
    "Graphik Compact <1.0625> {1.4}": [["Graphik Condensed <1.1429> {1.16}", "Produkt {1.4}"], ["Graphik Compact <1.1429> {1.16}", "Produkt {1.4}"], ["Graphik {1.185}", "Guardian Egyptian Text <0.965> {1.4}"], ["Brunel Deck {1.28}", "Brunel Text Short <1.1250> {1.32}"], ["Algebra <1.0357> {1.21}", "Algebra {1.3}"], ["Terza Reader <0.9286> {1.3}", "Terza Reader <0.9> {1.42}"], ["Feature Deck <0.9286> {1.28}", "Feature Text {1.4}"], ["Graphik X Cond <1.7143> {1}", "Frame Text {1.4}"], ["Produkt <0.9643> {1.21}", "Produkt {1.4}"], ["Lyon Display <1.0357> {1.21} [400]", "Lyon Text <1.0625> {1.4}"]],
    "Eugenio Sans Text <1.0625> {1.4}": [["Feature Deck {1.28}", "Eugenio Sans Text <1.0625> {1.4}"], ["Eugenio Sans {1.135}", "Eugenio Sans Text <1.0625> {1.4}"], ["Styrene B <0.9286> {1.15}", "Styrene B {1.4}"], ["Algebra Display <1.0357> {1.21}", "Atlas Grotesk {1.4}"], ["Caponi Display <1.1071> {1.1}", "Atlas Grotesk {1.4}"], ["Caponi Slab <1.1071> {1.1}", "Caponi Text <1.1250> {1.4}"]],
    "Duplicate Sans <1.1250> {1.4}": [["Duplicate Slab <1.0357> {1.15}", "Duplicate Slab <1.1250> {1.4}"], ["Duplicate Sans <1.1429> {1.12}", "Duplicate Sans <1.1250> {1.4}"], ["Duplicate Ionic <1.0357> {1.15}", "Duplicate Ionic <1.1250> {1.4}"], ["Canela Deck {1.29}", "Duplicate Slab <1.1250> {1.4}"], ["Algebra Display <1.0357> {1.21}", "Algebra {1.4}"], ["Caponi Slab <1.1071> {1.1}", "Caponi Text <1.1250> {1.4}"], ["Robinson <1.2857> {1.21}", "Robinson <1.0625> {1.4}"], ["Chiswick Deck <1.1> {1.22}", "Chiswick Text <1.1250> {1.4}"], ["Terza Reader <0.9286> {1.35}", "Terza Reader <0.9375> {1.42}"], ["Austin News Deck {1.15}", "Austin News Text {1.4}"]],
    "Antique No 6 {1.4}": [["Antique No 6 <0.9643> {1.19}", "Caslon Doric <1.0625> {1.4}"], ["Robinson <1.2857> {1.21}", "Robinson <1.0625> {1.4}"], ["Caslon Doric <0.9643> {1.23}", "Caslon Doric <1.0625> {1.4}"], ["Frame Head <0.9286> {1.28}", "Frame Text {1.4}"], ["Brunel Deck {1.28}", "Brunel Text Short <1.1250> {1.32}"], ["Marr Sans <1.0357> {1.28}", "Marr Sans <1.0625> {1.4}"]],
    "Chiswick Sans Text <1.1250> {1.4}": [["Chiswick Deck <0.9286> {1.2}", "Chiswick Text <1.1250> {1.4}"], ["Chiswick Sans <0.9286> {1.2}", "Darby Serif Text {1.4}"], ["Chiswick Grotesque <1.0357> {1.2}", "Chiswick Grotesque <1.1250> {1.4}"], ["Frame Head <0.9286> {1.28}", "Frame Text {1.4}"], ["Antique No 6 <0.9643> {1.19}", "Antique No 6 {1.4}"], ["Brunel Deck {1.22}", "Brunel Text Short <1.1250> {1.32}"]],
    "Le Jeune Text": [["Styrene A <0.9286> {1.15}", "Styrene B {1.4}"], ["Le Jeune Deck <1.12> {1.21}", "Graphik <0.9375> {1.4}"], ["Graphik Condensed <1.2> {1.16}", "Graphik <0.9375> {1.4}"], ["Graphik {1.185}", "Graphik <0.9375> {1.4}"], ["Antique No 6 <0.9643> {1.19}", "Caslon Doric {1.4}"], ["Duplicate Sans <1.1429> {1.12}", "Duplicate Sans <1.062> {1.4}"], ["Eugenio Sans {1.135}", "Eugenio Sans Text {1.4}"], ["Review {1.21}", "Review <0.9375> {1.4}"], ["Review Condensed <1.2857> {1.21}", "Review Condensed <1.0625> {1.4}"]]
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p",
      a: "a",
      hr: "hr",
      h2: "h2",
      h3: "h3"
    }, props.components), {FontSection} = _components;
    if (!FontSection) _missingMdxReference("FontSection", true);
    return _jsxs(_Fragment, {
      children: [_jsxs(FontSection, {
        children: [_jsxs("p", {
          className: "no-indent",
          children: ["Born and raised in East LA, I snaked through my adolescent years getting by on Cali-Mex fast food, entrees sauced up with high-fructose corn syrup, or, in complete contrast, the garden-to-table Sichuan cooking made by my grandmother (", _jsx("i", {
            children: "po-po"
          }), "). In the 1980s, my family emigrated from Taiwan to the San Gabriel Valley. With so many familiar people and supermarkets selling bitter melon conveniently clustered in one area, it was a comforting landing strip for Chinese and Taiwanese immigrants. This enclave of same-but-different East Asians made little room for other cultures, though not intentionally. We were freeways away from the other parts of Central LA, and the distance plus traffic proved an effective deterrent. A whole world of Ethiopian, Indian, Armenian, Iranian, and so many other communities existed out there, but they were well beyond our closest freeway exits. Neither those communities nor ours did a lot of diplomatic visits. In other words, I didn’t exactly grow up wading in a cultural melting pot."]
        }), _jsxs("div", {
          className: "posrel",
          children: [_jsxs(_components.p, {
            children: ["Then the internet changed everything. Suddenly I was exposed to a host of new, eye-opening diversions. People of all backgrounds ended up in the *NSYNC 4EVER chat room. Messages about the boy band would evolve into a/s/l icebreakers, and countries I had only ever studied in grade-school geography turned out to be real, habitable places.", _jsx("sup", {
              children: _jsx(_components.a, {
                href: "#asl",
                children: "1"
              })
            }), " By ninth grade, the chat rooms gave way to blogs, MP3 downloading sites, and intelligent search engines. During this time, I discovered and became obsessed with Bob Marley and the Wailers. Their music was my first foray into a larger world. This big, new, rad world contained Jamaica, an island country in the Caribbean. It was the birthplace of big, new, rad sounds I’d never heard before. This portal into a new world was admittedly superficial, but at fourteen years old I needed a catalyst. After all, my preteen score consisted of Tupac, Britney, and boy bands. Reggae was refreshingly foreign to me, a healthy addition to my music rotation."]
          }), _jsx("aside", {
            children: _jsx("ol", {
              children: _jsxs("li", {
                id: "asl",
                children: ["The expression ", _jsx("b", {
                  children: "a/s/l"
                }), " emerged in early internet chat rooms to denote age, sex, and location. —Ed."]
              })
            })
          })]
        }), _jsx(_components.p, {
          children: "In college, I graduated from Bob Marley to a broader swath of Jamaican music — rocksteady, dancehall, lovers rock. I was a member of the Cultural Affairs Alliance on campus. We were the brains and muscle behind the Jazz Reggae Festival, which drew some thirty thousand people every year. In a planning meeting, someone brought up the topic of food vendors, mentioning a Jamaican restaurant “south of Pico Boulevard that serves dope beef patties and coco bread.” My ears perked up. I had no idea what a “beef patty” was, or that “coco bread” wasn’t a dessert."
        }), _jsx(_components.p, {
          children: "The Jazz Reggae Festival came and went. It was incredible to see Buju Banton and I Wayne live. I was so busy running around that I never got to try the legendary patty and its accompanying coco bread. By the time graduation arrived, I had moved onto new obsessions: electro, cupcakes, and Coachella. I left my unanswered curiosity about patties behind."
        }), _jsx(_components.p, {
          children: "Four years after I graduated, I received a job offer in New York City assisting the editor in chief of a food magazine where I had previously worked. The role was a demotion and the salary was terrible. I had always dreamed of leaving Los Angeles, so I took the offer."
        }), _jsx(_components.p, {
          children: "I showed up with two suitcases at my childhood friend’s tiny Chinatown apartment, where the only windows faced a dreary shaftway. I didn’t have money to buy a mattress, so I slept in my friend’s bed with her and her two cats. The magazine gave me a few weeks off to settle in before starting my new job. Winter seized the city with its occasional blizzard and bone-chilling cold, but I decided to go out exploring anyway."
        }), _jsx(_components.p, {
          children: "Plotting out my “broke girl’s guide to NYC” itinerary, I stumbled upon a world of absurdly affordable places to eat in the city: Punjab Deli, Vanessa’s Dumpling House, Veselka, B&H Dairy, Joe’s Pizza, El Castillo de Jagua, and San Loco. These institutions eventually became my go-tos whenever I felt like treating myself to a meal out."
        }), _jsxs(_components.p, {
          children: ["One day, after walking through midtown, I ducked into Grand Central Station for a snack. Golden Krust’s sunrise emblem caught my eye from afar. It wasn’t yet on my list, but I was hungry. I got a whiff of oven-fresh buttery crusts as I approached the restaurant. These rectangular hand pies looked like the Hot Pockets of my childhood but were tinted yellow, stacked in a heated display case, and seemed rather precarious, sweating under the artificial lights. A moment later, I realized that these were Jamaican patties — ", _jsx("em", {
            children: "the"
          }), " mysterious Jamaican patties I still hadn’t tried."]
        }), _jsx(_components.p, {
          children: "“Excuse me? Can I help you?” the cashier asked impatiently. I had been staring at the patties for a while, and a line of people was starting to form behind me. So I ordered my very first beef patty with coco bread. And I was immediately shocked. The bread wasn’t made from cocoa at all."
        }), _jsx(_components.p, {
          children: "Biting into the bread, chewing through the crispy pastry crust, and landing on the spiced ground beef filling was a transformative sequence. This food fed my soul. Every bite reached deep. I would later find out that Golden Krust was the gateway to other, fresher, tastier patties across the boroughs. In that moment, though, Golden Krust’s patty was as revelatory as the first time I heard Bob Marley and the Wailers croon “Is This Love.”"
        }), _jsx(_components.p, {
          children: "Since that patty rite of passage twelve years ago, I’ve eaten more than my fair share of them. What felt incredibly foreign and impossible to find when I lived in LA is now available a block away from my apartment in Bed-Stuy, Brooklyn. I am so grateful for the Jamaican community that descended here in the 1960s, bringing with them this iconic working-class snack. Patties are affordable, convenient, and delicious, which is why they’ve become a New York mainstay."
        }), _jsx(_components.p, {
          children: "I always believed that living in New York for more than ten years would turn me into a real New Yorker. It hasn’t. But biting into a patty certainly makes me feel like one."
        })]
      }), "\n", _jsx(_components.hr, {}), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "RECIPE"
        })
      }), "\n", _jsx(FontSection, {
        children: _jsx("p", {
          className: "no-indent",
          children: "Now that I eat mostly plant-based and gluten-free, I have a hard time throwing back patties like I used to, especially the meat-filled ones. So here’s my take on the classic Jamaican patty, made gluten-free with plantain flour and vegan with a five-spice jackfruit filling."
        })
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "INGREDIENTS"
        })
      }), "\n", _jsxs("div", {
        className: "two-col",
        children: [_jsxs("div", {
          className: "col",
          children: [_jsx(FontSection, {
            children: _jsx("h3", {
              children: "DOUGH"
            })
          }), _jsx(FontSection, {
            children: _jsxs("ul", {
              children: [_jsxs("li", {
                children: [_jsx("b", {
                  children: "1½"
                }), " cups sorghum flour"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1"
                }), " cup plantain flour"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "½"
                }), " cup tapioca flour"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1"
                }), " teaspoon salt"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "⅔"
                }), " cup coconut oil or vegan butter"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "4"
                }), " tablespoons agave or maple syrup"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "4"
                }), " tablespoons ice water"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1"
                }), " tablespoon turmeric"]
              })]
            })
          })]
        }), _jsxs("div", {
          className: "col",
          children: [_jsx(FontSection, {
            children: _jsx("h3", {
              children: "FILLING"
            })
          }), _jsx(FontSection, {
            children: _jsxs("ul", {
              children: [_jsxs("li", {
                children: [_jsx("b", {
                  children: "2"
                }), " tablespoons olive oil"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "½"
                }), " onion, sliced"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "2"
                }), " cloves garlic"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "2"
                }), " tablespoons ginger"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1"
                }), " can jackfruit"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1½"
                }), " teaspoons salt"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1"
                }), " teaspoon black pepper"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "1"
                }), " teaspoon white pepper"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "2"
                }), " teaspoons ground five spice powder"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "½"
                }), " cup water"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "3"
                }), " tablespoons coconut palm sugar"]
              }), "\n", _jsxs("li", {
                children: [_jsx("b", {
                  children: "¼"
                }), " cup tamari"]
              })]
            })
          })]
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h3, {
          children: "INSTRUCTIONS"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsxs("ol", {
          className: "instructions",
          children: [_jsx("li", {
            children: "Heat oil over medium heat in a large pan or Dutch oven. Add the onions and garlic and cook until translucent. Add the jackfruit, spices, and tamari. Stir to coat evenly. Add the water and coconut palm sugar and bring to a simmer. Reduce heat, cover and simmer for 30 minutes, until the liquid is mostly absorbed and the fruit has broken down. Set filling aside."
          }), "\n", _jsx("li", {
            children: "Blend all flours, salt, turmeric, and coconut oil or vegan butter in a food processor until crumbly. Slowly add water and agave or maple syrup until dough forms. In a bowl, knead dough until smooth. Roll out between two large pieces of parchment paper (or roll out in two batches) until dough is about a quarter of an inch thick. Using a 4½-inch biscuit cutter, cut circles out of dough. Scraps can be collected and rolled out again for more circles."
          }), "\n", _jsx("li", {
            children: "Preheat oven to 350ºF."
          }), "\n", _jsx("li", {
            children: "Spoon 2 tablespoons of the filling into the center of the dough circle. Take another circle and gently layer it on top of the filled bottom piece. Enclose by pressing around the sides of the top layer with the back of a fork, creating fork indentations all around."
          }), "\n", _jsx("li", {
            children: "Once all patties are filled, bake for 15–20 minutes or until brown. Let cool and enjoy."
          })]
        }), _jsxs("p", {
          className: "bio",
          children: [_jsx("b", {
            children: "Diane Chang"
          }), " is a writer and cook in Brooklyn, New York who has written for ", _jsx("cite", {
            children: "Bon Appétit"
          }), ", ", _jsx("cite", {
            children: "Eater"
          }), ", ", _jsx("cite", {
            children: "Vice"
          }), ", ", _jsx("cite", {
            children: "New York Magazine"
          }), ", and more. Follow her journey on Instagram at ", _jsx(_components.a, {
            href: "https://www.instagram.com/eatingpopos/",
            children: "@eatingpopos"
          }), "."]
        })]
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
