import helen, {
  meta as helenMeta,
} from "../content/helen-rosner-charts-the-banal-and-the-beautiful.mdx"
import kuih, {
  meta as kuihMeta,
} from "../content/a-beginners-guide-to-kuih.mdx"
import resist, { meta as resistMeta } from "../content/food-as-resistance.mdx"
import regular, { meta as regularMeta } from "../content/becoming-a-regular.mdx"
import patty, {
  meta as pattyMeta,
} from "../content/this-is-love-a-riff-on-the-jamaican-patty.mdx"
import conan, {
  meta as conanMeta,
} from "../content/harriet-amber-in-the-conan-arcade.mdx"

export const articles = [
  { component: helen, meta: helenMeta },
  { component: regular, meta: regularMeta },
  { component: conan, meta: conanMeta },
  { component: patty, meta: pattyMeta },
  { component: resist, meta: resistMeta },
  { component: kuih, meta: kuihMeta },
]
