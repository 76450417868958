/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import photo from "/public/images/chinese-food(0)_2.png";
import cover from "/public/images/rosner_2x2_v3.png";
export const meta = {
  title: "Helen Rosner on the&nbsp;Banal and the&nbsp;Beautiful",
  author: "Navneet Alang",
  credit: "Illustrations by Derrick Schultz",
  position: 1,
  slug: "helen-rosner-on-the-banal-and-the-beautiful",
  category: "Interview",
  type: "interview",
  cover: {
    src: cover,
    preview: "",
    alt: "Generative portrait of Helen Rosner wearing dark-rimmed glasses, composed of organic gustatory and floral forms emerging from a dark background.",
    position: "before"
  },
  paragraphs: "spacing",
  byline_family: 1,
  pulled_quotes: true,
  excerpt: "The cliff of connoisseurship.",
  weights: {
    text: 400,
    question: 600,
    hed: 500,
    byline: 600,
    sidebar: 400
  },
  families: {
    text: 1,
    headings: 2,
    pullquotes: 2,
    questions: 3,
    footnotes: 3,
    byline: 1,
    bio: 1
  },
  layouts: {
    "Graphik": {
      title: "Helen Rosner on the Banal and the Beautiful"
    },
    "Original Sans": {
      title: "Helen Rosner <br/>on the&nbsp;Banal & the <br/><b>Beautiful</b>"
    },
    "Le Jeune": {
      title: "<b>Helen Rosner</b> on the&nbsp;Banal and the Beautiful",
      author: "Navneet \nAlang"
    },
    "Lyon": {
      excerpt: "“I think that writing about food that is underappreciated, even if it is extraordinarily popular, gives us space to talk about our daily lives in a way that writing about things that are special, and only things that are special, does not.”"
    },
    "Eugenia": {
      title: "Helen Rosner on the Banal and Beautiful, interviewed by Navneet&nbsp;Alang"
    },
    "Proxy": {
      title: "Helen Rosner on&nbsp;the \nBanal and the Beautiful"
    }
  },
  cssSelectors: [{
    selector: ".body p",
    output: "body"
  }, {
    selector: ".article-title",
    output: "h1"
  }, {
    selector: ".byline",
    output: "header address",
    excludeFromCSS: true
  }, {
    selector: ".bio :is(b, strong)",
    output: "strong",
    excludeFromCSS: true
  }, {
    selector: ".bio :is(i, em, cite)",
    output: "em",
    excludeFromCSS: true
  }, {
    selector: "p :is(b, strong)",
    output: "strong",
    excludeFromCSS: true
  }, {
    selector: "p :is(i, em, cite)",
    output: "em",
    excludeFromCSS: true
  }, {
    selector: "blockquote p",
    output: "blockquote"
  }, {
    selector: "h4",
    output: "/* Questions */\nh2"
  }],
  pairings: {
    "Algebra {1.3}": [["Atlas Grotesk {1.185}", "Atlas Grotesk {1.4} [500]"], ["Algebra Display <1.0357> {1.21}", "Algebra {1.3} [400] (italic)"], ["Eugenio Sans {1.135}", "Eugenio Sans Text <1.0625> {1.4} [600]"], ["Graphik {1.185}", "Graphik <0.9375> {1.4}"], ["Graphik Condensed <1.1429> {1.1}", "Graphik Compact <1.0625> {1.4}"], ["Styrene A <0.9286> {1.15}", "Styrene B {1.4} [600]"], ["Eugenio Serif <1.1071> {1.2}", "Eugenio Sans Text {1.4}"], ["Action Condensed Bold <1.7143> {1} [400]", "Action Text Bright {1.4} [500]"]],
    "Austin Text <1.2075> {1.3}": [["Canela Deck {1.29}", "Graphik {1.4}"], ["Eugenio Sans {1.135}", "Eugenio Sans Text <1.0625> {1.45}"], ["Styrene B <0.9286> {1.15}", "Styrene B {1.4} [500]"], ["Antique No 6 <0.9643> {1.19}", "Antique No 6 {1.4}"], ["Austin <1.2143> {1.1} (italic) [300]", "Austin Text <1.2075> {1.3}"], ["Produkt <0.9643> {1.21} [700]", "Produkt <1.0625> {1.4} [400]"], ["Chiswick Grotesque <1.0357> {1.2} [200]", "Chiswick Sans Text <1.1250> {1.4}"], ["Chiswick Deck <1.1> {1.28}", "Austin Text <1.2075> {1.3}"], ["Darby Sans <1.0357> {1.2}", "Darby Sans {1.42} [500]"], ["Marr Sans <1.0357> {1.2}", "Marr Sans <1.0625> {1.4}"], ["Marr Sans Condensed <1.2> {1.16}", "Marr Sans <1.0625> {1.4}"]],
    "Caponi Text <1.1250> {1.4}": [["Caponi Display <1.1071> {1.1}", "Caponi Text <1.1250> {1.4}"], ["Caponi Slab <1.1071> {1.1} [600]", "Caponi Text <1.1250> {1.4}"], ["Action Condensed Medium <1.7143> {1}", "Action Text Bright {1.425}"], ["Chiswick Grotesque <1.0357> {1.2}", "Chiswick Grotesque <1.1250> {1.4}"], ["Eugenio Sans {1.135}", "Eugenio Sans Text <1.0625> {1.4}"], ["Feature Deck {1.28} [300]", "Review <0.9375> {1.4}"], ["Graphik {1.185}", "Graphik <1.0625> {1.4}"], ["Graphik Condensed <1.1429> {1.16} [600]", "Graphik Compact <1.0625> {1.4}"], ["Le Jeune Deck <1.15> {1.21}", "Caponi Text <1.1250> {1.4}"]],
    "Caslon Ionic {1.4}": [["Caslon Ionic <0.9643> {1.19} [400]", "Caslon Ionic {1.4} [400] (italic)"], ["Austin <1.2143> {1.1}", "Caslon Ionic {1.4}"], ["Brunel Deck <1.2> {1.28}", "Antique No 6 {1.4}"], ["Caslon Doric <0.9643> {1.23} [400]", "Caslon Doric <1.0625> {1.4}"], ["Terza Reader <0.9286> {1.3} (italic)", "Terza Reader {1.4} (italic)"], ["Duplicate Sans <1.1429> {1.12}", "Duplicate Sans <1.1250> {1.4} [500]"], ["Lyon Display <1.0357> {1.21}", "Atlas Grotesk {1.4}"], ["Publico Headline", "Caslon Ionic {1.4}"], ["Styrene A <0.88> {1.15}", "Styrene A {1.4}"]],
    "Eugenio Sans Text <1.0625> {1.4}": [["Eugenio Serif <1.1071> {1.2}", "Darby Serif Text {1.4}"], ["Eugenio Sans {1.135}", "Eugenio Sans Text {1.4}"], ["Lyon Display <1.0357> {1.21} (italic)", "Lyon Text <1.0625> {1.4}"], ["Terza Reader <0.9286> {1.3}", "Terza Reader <0.9375> {1.5}"], ["Algebra Display <1.0357> {1.21}", "Algebra {1.3}"], ["Le Jeune Deck <1.0357> {1.21}", "Le Jeune Text <1.0625> {1.4}"], ["Chiswick Sans <1.2> {1.28}", "Eugenio Sans Text {1.4}"], ["Darby Serif Display <1.12> {1.21}", "Darby Serif Text {1.4} [400]"], ["Robinson <1.12> {1.15}", "Robinson <1.0625> {1.4} [400]"]],
    "Marr Sans <1.0625> {1.4}": [["Sanomat {1.21} [300]", "Marr Sans <1.0625> {1.4}"], ["Marr Sans <1.0357> {1.12} [700]", "Marr Sans <1.0625> {1.4}"], ["Marr Sans Condensed <1.25> {1.125} [300]", "Marr Sans <1.0625> {1.4}"], ["Darby Serif Display <1.2857> {1.21}", "Darby Serif Text <1.0625> {1.4} [500]"], ["Brunel Deck {1.28}", "Brunel Text Short <1.1250> {1.32} [400]"], ["Antique No 6 <0.9643> {1.19}", "Antique No 6 {1.4}"], ["Austin News Deck <1.1> {1.12}", "Austin News Text {1.4}"], ["Darby Sans Poster <1.1429> {1.2}", "Marr Sans <1.0625> {1.4}"], ["Frame Head <0.9286> {1.28}", "Frame Text {1.4}"], ["Portrait <1.1429> {1.12}", "Portrait Text <1.175> {1.35}"]]
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p",
      a: "a",
      h4: "h4",
      blockquote: "blockquote"
    }, props.components), {FontSection, Image} = _components;
    if (!FontSection) _missingMdxReference("FontSection", true);
    if (!Image) _missingMdxReference("Image", true);
    return _jsxs(_Fragment, {
      children: [_jsxs(FontSection, {
        children: [_jsxs(_components.p, {
          children: ["Without exactly meaning to, Helen Rosner sits atop the world of American food. Her impressive curriculum vitae includes stints reviewing cookbooks, editing at ", _jsx("cite", {
            children: "Grub Street"
          }), ", and writing for ", _jsx("cite", {
            children: "Eater"
          }), ". She is now a staff writer at a little magazine you may have heard of called the ", _jsx("cite", {
            children: "New Yorker"
          }), "."]
        }), _jsxs(_components.p, {
          children: ["There, Rosner files a weekly(ish) column that is ostensibly about food, but is really about contemporary culture writ large. Topics include ", _jsx(_components.a, {
            href: "https://www.newyorker.com/culture/annals-of-gastronomy/how-to-get-a-table-at-carbone",
            children: "New York’s penchant for exclusive restaurants"
          }), ", like Carbone, which transported itself from Greenwich Village to pandemic-weary Miami; ", _jsx(_components.a, {
            href: "https://www.newyorker.com/culture/annals-of-gastronomy/the-timeless-fantasy-of-stanley-tucci-eating-italian-food",
            children: "the beauty of Italy on film"
          }), ", but particularly when a beautiful Stanley Tucci is one’s guide; and ", _jsx(_components.a, {
            href: "https://www.newyorker.com/culture/annals-of-gastronomy/the-haunting-afterlife-of-anthony-bourdain",
            children: "the ethics of using artificial intelligence to fake Anthony Bourdain’s voice"
          }), " in Morgan Neville’s documentary about him."]
        }), _jsx(_components.p, {
          children: "Rosner has thus become a chronicler of where food and its new status as pop culture fits amid the churning waves of a world undergoing profound changes. We spoke via phone on a December day when she was out of town, at a farmhouse in the Catskills. Our conversation has been condensed and edited for clarity."
        })]
      }), "\n", _jsxs("div", {
        className: "interview-body",
        children: [_jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NAVNEET ALANG."
            }), " A common contemporary phenomenon is, very simply, that there is so much information. And New York is almost overrun with restaurants. I often feel: “Somebody just tell me where I should go!” So I was curious what you thought about the function of criticism in relation to this new context — that is, how do you separate the signal from the noise?"]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HELEN ROSNER."
            }), " I don’t think there is one answer. But the service model of criticism is very pure: you have a limited amount of money, you have a limited amount of time, and we are paying someone to consume all of it so that you can know which ones are worth your time."]
          }), _jsx(_components.p, {
            children: "The problem is that people are people. One person’s view is always going to be limited by the fact that it is one person’s view, regardless of who that person is. And obviously, the history of “mainstream” food sections and general-interest magazines that have a restaurant critic is of mostly putting rich, white people into those roles."
          }), _jsx(_components.p, {
            children: "But that kind of service, criticism, is at its best when it’s the same person writing over and over, week by week by week, so you can say, like, “They and I always totally disagree about what makes for a good pizza, or their take on hot pot is fantastic, so whenever they say a price is worth it, I’m going to totally trust them.”"
          }), _jsx(_components.p, {
            children: "I think that’s why criticism exists on a national scale, and criticism interested in addressing these problems of the fundamental limit of the individual has moved more toward essayistic cultural criticism, or uses the restaurant or the dish or whatever it might be as sort of the entry point for a different conversation."
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " A related question, then, about restaurant critics: New York is a bit of a specific example in that there’s such a mass of restaurants that there’s also a large audience comparatively that is willing to read about new restaurants, and there can be a bunch of critics. Why do you think that is?"]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " I do think there are things specific to New York — not unique to New York, but specific to New York — that make it a city particularly suited to restaurant criticism. In New York, and it is this cliché, but it’s largely true that our apartments are fairly small and entertaining doesn’t really happen at home. Things like bars and restaurants are extensions of people’s homes in cities like New York, and other extremely dense, extremely expensive cities like Hong Kong, Mexico City, Tokyo."]
          }), _jsx(_components.p, {
            children: "So restaurant-going is not just “What do I want to eat?” It’s also “Where should I see you? Where should we have this social interaction?” And picking the restaurant is part of the fun of it."
          }), _jsx(_components.p, {
            children: "And two, there’s a demand for information about where to eat. Setting aside all of the last thirty years, New York just has the cultural, intellectual, social capital for restaurant criticism. I don’t mean intellectual in, like, a smart-to-stupid axis. I mean that it’s part of the intellect of the city, that there’s an appetite for thinking about food critically and wanting to know about new things."
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " Part of that focus on criticism is that, at least to an outsider, it seems like it’s part of the substance of social interaction — that people are always talking about where to get the best bagel or the best pizza. It feels very much part of life in New York to develop a repertoire or your own little list of the best places to go."]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " No, I think that’s accurate. Again, I don’t think it’s unique to New York. You cannot have a subjective experience without in some way hierarchizing it."]
          }), _jsxs(_components.p, {
            children: ["But I do think that New York has all of the conditions for it to flourish. And a thing that happened with every single topic was the advent of access to the internet, where suddenly you could find people who are as passionate about ", _jsx("i", {
              children: "x"
            }), " as you are. So you could start applying much more rigorous, much more granular, much more histrionically obsessive criteria. So these questions are, well, “What is the best pizza?” Or: “What is the best bagel?” And it stops being like, you know, “Oh, so and so who I know who lives in Manhattan says that I should go to H&H and I shouldn’t go to Ess-a-Bagel Bagel,” which are these two big bagel places in the city."]
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " Have you ever seen ... There’s a video by a voice actor named Sung Wong Cho that articulates the difference between when you’re just interested in something, and then after you discover the subreddit for it. And at first he says “I like to drink water.” But after that he goes on a diatribe about the pH of the water, what kind of glass you drink it out of. It really, really captures the absurdity that we all kind of fall into sometimes."]
          })
        }), _jsx(FontSection, {
          children: _jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " I think of that as the cliff of connoisseurship. When you start paying\nattention to something, the margins become much, much, much smaller — you know,\nthe more knowledge and expertise you have, the more experience you have. And\nthese tiny margins start mattering more and more and more and more and more\nuntil you’re effectively communicating in a language that is unintelligible to\nanybody who is outside of your micro subreddit, or whatever it might be."]
          })
        }), _jsx(FontSection, {
          children: _jsxs(_components.blockquote, {
            children: ["\n", _jsx(_components.p, {
              children: "“It’s nice to be obsessed. It’s nice to find joy in knowledge and mastery\nand expertise.”"
            }), "\n"]
          })
        }), _jsxs(FontSection, {
          children: [_jsx(_components.p, {
            children: "It’s that idea of being extraordinarily granular about microscopic differences in like, how much does this pizza place ... what percentage do they hydrate their dough versus this other place, or obviously, the wine world, or chocolate, or beer — and there are in fact people who do this about drinking water!"
          }), _jsx(_components.p, {
            children: "And you know, it’s nice to be obsessed. It’s nice to find joy in knowledge and mastery and expertise."
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " That’s a positive spin on it. But I did want to ask you, then, that if the web has produced these obsessive communities, has that kind of specialization also become part of the vocabulary of food media? Do you think that has exacerbated the problem of barriers, or barriers produced by elitism? Or has there been a countervailing trend?"]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " I think the answer is both simultaneously. On one hand, facts, which are essentially what is at the root of this kind of information, do produce connoisseurship."]
          }), _jsx(_components.p, {
            children: "So on one hand, when a conversation comes down to “science” or “facts” — which is what a lot of these connoisseurship conversations are — it comes down to things like “Where was the tuna caught? What time of year was it? How fatty was it? What’s the protein-to-fat ratio?” These things often come down to questions of biology, ecology, chemistry, mathematical ratios, they’re very much scientific-method questions. And so in that respect, you can have access to facts, you can synthesize and analyze information. Regardless of what your background might be, it’s that idea that science doesn’t care who you are."
          }), _jsx(_components.p, {
            children: "But on the other hand, questions of taste or connoisseurship are in many cases “subjective facts” — which I know is an oxymoron. And expecting expertise in a reader, and expecting expertise in the writer, can be very off-putting. In a general-interest publication, or if it’s in something that in all other respects is being pitched at me on my level, then I can find that very alienating and feel like this isn’t a community that I’m allowed to participate in."
          }), _jsx(_components.p, {
            children: "So I think the problem with expertise or connoisseurship, or whatever we want to call it, itself becoming a cultural trend is that there’s no room in that for the on-ramp to expertise."
          }), _jsx(_components.p, {
            children: "There are a million articles out there that are like “Coffee for Beginners,” right? “Sushi for Beginners.” Whatever it might be for beginners. And that’s cool. And then there are these super high-level conversations that feel like they’re happening in ancient Greek and I have no idea what’s being said. And the process of going from neophyte to expert is not included in either the creation world or the consumption world. I think one of the most important questions that anybody can ask when they’re creating something is: Who is this for?"
          }), _jsx(_components.p, {
            children: "And this connects to the cultural “other” in food, too, right? As in: What words are italicized? What words get parenthetical asides explaining what they are? Do we say “the Thanksgiving turkey (a turkey is a large fowl)”? No. So what things are assumed is, in many respects, a question of expertise."
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " Related to that is the concept of “elevation.” There’s a recent example here in Toronto where there’s a new restaurant that everyone is talking about called Mimi’s Chinese. And their shtick is that they try to focus on regional cuisines within Chinese cuisine. And it’s well reviewed, and fancy, and everyone loves it, but also there’s half a million Chinese people in Toronto, and there are tons of brilliant Chinese restaurants in the suburbs. Is that dynamic still a concern?"]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " So, like a lot of people, I got really, really into TikTok in the last year and a half. And so there’s this cultural phenomenon that started arriving on Tumblr and TikTok, which is the idea of “aesthetic,” where, for example, a Gen Zer can be presented with, like, you know, a chair that’s been upholstered and in pale pink Sherpa material and say, “Oh, that’s so aesthetic.” So, not “aesthetics” as we might understand it, but ", _jsx("em", {
              children: "aesthetic"
            }), " as itself an aesthetic."]
          }), _jsx(_components.p, {
            children: "And I think the idea of imposing the aesthetic onto something is really fascinating as a phenomenon, because it tracks very much with the phenomenon of what in the culinary world we talk about as “elevation.” Because, as you say, the food at this restaurant is being served at other restaurants all over Toronto. Like, it’s not necessarily original. And even if it is deconstructing something, or presenting it in a slightly different way, the roots are in the same place. So, where is the elevation happening — as opposed to just differentiation?"
          }), _jsx(_components.p, {
            children: "I think that what is happening when we talk about a food being elevated is that it is being wrapped in a different cultural aesthetic than the one in which it originated. So, when we talk about “elevation,” that context is usually European: somewhat minimal plates, French-style service, the trappings of what we have been trained to understand as high-end cuisine, with the ingredients, the preparations, the techniques, etc. of a different universe."
          }), _jsx(_components.p, {
            children: "But “elevation” itself obviously is a problematic term because of the hierarchy it implies — that because you’re elevating it, you’re implying that it was in a low place. The status implications of using the term “elevation” are problematic and destructive, and rooted in white supremacy and European hegemony and the class architecture of high-end dining versus “cheap food,” which is also associated with, you know, dirtiness, and filth, and undesirability. The racialization of these hierarchies is built in."
          }), _jsx(_components.p, {
            children: "But setting aside the term “elevation,” that kind of context shift itself can be really thrilling. I think that can be one of the places in which some of the most exciting boundary-pushing, identity-testing conversations with food and cooking and restaurant-going can occur. Not always, of course. Sometimes it’s “We threw some tofu into macaroni and cheese, and now we’re calling it Chinese macaroni and cheese.” I feel like I’m trying to say two things in parallel. But understanding that phenomenon of context-shifting food without that term attached to it gives us room to find it interesting, along with being problematic."
          })]
        }), _jsx(Image, {
          src: photo,
          alt: "Detailed, dark generative image of a table laden with food, dishes, and utensils—very Dutch Masters meets Peter Greenaway."
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " I’ve been thinking a lot about this recently — specifically that I’m Indian, or at least of Indian descent, and I have eaten many cheap, bad curries. So when somebody does something interesting, or does something new with that, it appeals to me. But how do we talk about change? Or: Who gets to own change? How does one do what I think you’re rightly calling boundary-pushing without replicating the racialization of a hierarchy of low and high?"]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " Let the record show that I am pausing! But my first thought is: Don’t say “I fixed it.” I mean, there probably are some people in some contexts who can say “I fixed it” — people who’ve earned the right through experience or identity or both to claim sufficient ownership of a food or ingredient or a technique to say, “You know, I made it better.”"]
          }), _jsxs("div", {
            className: "posrel",
            children: [_jsxs(_components.p, {
              children: ["But then you get the trope of the wellness-oriented white person who opens a Chinese restaurant, and they say “It’s not going to make you feel gross. It’s clean Chinese food.”", _jsx("sup", {
                children: _jsx(_components.a, {
                  href: "#lucky-lees",
                  children: "1"
                })
              }), " The subtext of that is: I fixed the problem, I fixed this food."]
            }), _jsx("aside", {
              children: _jsx("ol", {
                start: "1",
                children: _jsxs("li", {
                  id: "lucky-lees",
                  children: ["A reference to Lucky Lee’s. See Serena Dai, “", _jsx("a", {
                    target: "_blank",
                    href: "https://ny.eater.com/2019/12/6/20999639/lucky-lees-greenwich-village-clean-chinese-closed",
                    children: "‘Clean’ Chinese Restaurant Closes Less than a Year after Racism Controversy"
                  }), ",” ", _jsx("cite", {
                    children: "Eater"
                  }), ", December 6, 2019. —Ed."]
                })
              })
            })]
          }), _jsx(_components.p, {
            children: "Elevating something is slightly different. Even if we accept “elevation” as a term, and even if we sort of engage with the idea on its own, in its own context, I think that elevating is not the same as fixing. I think one way of thinking about elevating is saying, “Here’s something that you have perhaps failed to appreciate because of its context.”"
          }), _jsx(_components.p, {
            children: "And so, when somebody is doing that with food, to which they can lay claim to for whatever reason, I think that that can be quite powerful. We can debate the merits of whether culinary diplomacy is successful, or if it’s just playing into, say, the capitalistic consumption of the subaltern. But it might be a way of reaching the culture, the moneyed people and saying, “Hey, pay attention to this thing that you’ve previously dismissed as food that does not rise to your level, and recognize that not only are you worthy of it, but perhaps we’re not even worthy of it, it is greater than you had previously imagined.” Like, that’s the best-case context for “elevation.”"
          }), _jsx(_components.p, {
            children: "But I think you’re right, like sometimes food is better than other foods, like we have to be able to say that. For example, I grew up eating chicken paprikash, which is a traditional Hungarian dish. And then as an adult, I went to a Hungarian restaurant and I ordered chicken paprikash and it turns out it comes with sour cream and was cooked with truckloads of butter. It’s so good! So much better."
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.blockquote, {
            children: ["\n", _jsx(_components.p, {
              children: "“I think just because you have done it better doesn’t mean that everybody\nwho has done it other ways is worthless.”"
            }), "\n"]
          })
        }), _jsx(FontSection, {
          children: _jsxs("div", {
            className: "posrel",
            children: [_jsxs(_components.p, {
              children: ["And I think that we have to be allowed to experience what I said before are these subjective facts — that some food is better than other foods, some preparations are better than other preparations, some pigs taste better than other pigs. You don’t want to reach this Harrison Bergeron kind of universe where, like, every food is equally valuable and we have to handicap everything that’s not great.", _jsx("sup", {
                children: _jsx(_components.a, {
                  href: "#bergeron",
                  children: "2"
                })
              }), " Like, obviously, that’s not the case: some things are better and often that is because of care or sourcing or technique or innovation."]
            }), _jsx(_components.p, {
              children: "I think that when “better” is framed as obliterating identity — like chicken paprikash made with butter and sour cream is better than chicken paprikash made with margarine and no sour cream, therefore it should not exist — you also eliminate the context that gave rise to that thing. The reason that the chicken paprikash I grew up eating was not as good was because I was eating a version that was made according to religious dietary law, and it’s a religious identity that’s important to me."
            }), _jsx(_components.p, {
              children: "So you can’t just be like, “Oh, these fucking idiots who are eating their chicken paprikash without sour cream.” That doesn’t fly. I think just because you have done it better doesn’t mean that everybody who has done it other ways is worthless."
            }), _jsx("aside", {
              children: _jsx("ol", {
                start: "2",
                children: _jsx("li", {
                  id: "bergeron",
                  children: "“Harrison Bergeron” is a dystopian short story by Kurt Vonnegut set in the United States in the year 2081, when  amendments to the US Constitution ensured that all Americans were equal through the institution of mandatory “handicaps.” —Ed."
                })
              })
            })]
          })
        }), _jsx(FontSection, {
          children: _jsxs("div", {
            className: "posrel",
            children: [_jsxs(_components.h4, {
              children: [_jsx("b", {
                children: "NA."
              }), " I want to shift a bit to ask you about the changes in food media over the past couple of years. It’s cliché at this point, but post ", _jsx("cite", {
                children: "Bon Appétit"
              }), _jsx("sup", {
                children: _jsx(_components.a, {
                  href: "#bon-appetit",
                  children: "3"
                })
              }), " and Alison Roman,", _jsx("sup", {
                children: _jsx(_components.a, {
                  href: "#roman",
                  children: "4"
                })
              }), " it felt like there was some kind of a reckoning within food media. Do you have thoughts on the nature or legitimacy of that change? That is, whether or not it’s been a real change or whether or not it’s just been lip service?"]
            }), _jsx("aside", {
              children: _jsxs("ol", {
                start: "3",
                children: [_jsxs("li", {
                  id: "bon-appetit",
                  children: ["In 2020, a photo surfaced of ", _jsx("cite", {
                    children: "Bon Appétit"
                  }), " editor in chief Adam Rapoport in brownface, leading to his resignation and a larger reckoning with how staffers of color were treated and compensated at the magazine. See Jenny G. Zhang, “", _jsx("a", {
                    target: "_blank",
                    href: "https://www.eater.com/2020/8/6/21357341/priya-krishna-rick-martinez-sohla-el-waylly-resign-bon-appetit-test-kitchen-videos",
                    children: "Multiple Bon Appétit Stars Resign From Appearing in Test Kitchen Videos"
                  }), ",” ", _jsx("cite", {
                    children: "Eater"
                  }), ", August 12, 2020. —Ed."]
                }), "\n", _jsxs("li", {
                  id: "roman",
                  children: ["Roman met with widespread criticism after accusing Chrissy Teigen and Marie Kondo of capitalizing on their popularity. See Jaya Saxena, “", _jsx("a", {
                    target: "_blank",
                    href: "https://www.eater.com/2020/5/11/21254554/chrissy-teigen-alison-roman-twitter-fallout-explained",
                    children: "What Exactly Is Going on Between Chrissy Teigen and Alison Roman on Twitter?"
                  }), "” ", _jsx("cite", {
                    children: "Eater"
                  }), ", May 11, 2020. —Ed."]
                })]
              })
            })]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " I think that, like so many things, the pandemic exacerbated it. The pandemic didn’t create it, but it exacerbated it."]
          }), _jsxs(_components.p, {
            children: ["What happened in the last twenty or thirty years was the creation of the concept of food as cultural category, as opposed to cooking and entertaining on one hand, and dining out on the other. I remember the ", _jsx("cite", {
              children: "New York Times"
            }), " had two different sections — one for dining out and one for dining in — and now they have one section called “Food.” And that really encapsulates it. Now, there’s food as a cultural phenomenon."]
          }), _jsx(_components.p, {
            children: "And the avatars of that were the celebrity chefs. And then that sort of disappeared. I think that the decline of celebrity chef culture over the last decade or so has left this fascinating void in food media as an entity, where people are suddenly like, Well, shit, what do we write about?"
          }), _jsx(_components.p, {
            children: "But food media has always been concerned with what food media is — you know, “What are we doing and what are we doing wrong?” And inherent within that has always been that to some degree this conversation has almost always been had, in the loudest ways and in the biggest channels, by white people, by largely wealthy people, by people who, you know, had elite education or had access to what would be considered mainstream American cultural avenues."
          }), _jsx(_components.p, {
            children: "Then you have what you describe as the sort of reckoning that happened in the last year or two. But I don’t know the answer to “Is this actually changing?” Like, is it actually shifting? Because the problem with systems is that they are made up of people. And every individual person doesn’t feel like they are, you know, part of the system. It’s a particle and wave problem, right? That a person thinks of themselves as a particle, but what we want to do is have people think like a wave."
          }), _jsx(_components.p, {
            children: "But whether it changes things on a systemic level remains to be seen. Lots of people got access to contracts, and jobs, and video cameras and visibility and speaking gigs and book deals, etc., that they should have had for a really fucking long time. And they weren’t getting these things because the establishment is like, rarely interested in changing the way it does business. But what remains to be seen is if those particles become a wave."
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.h4, {
            children: [_jsx("b", {
              children: "NA."
            }), " The last thing I wanted to ask you was how what we’ve talked about so far relates to food criticism. You’ve been careful to point out that you are not a reviewer — you are someone who writes about food. So, perhaps this is too big a question, but: How does one approach the need or the desire for food media to be about connoisseurship — to be about things that are genuinely good — versus things that people actually engage with on a day-to-day basis?"]
          })
        }), _jsxs(FontSection, {
          children: [_jsxs(_components.p, {
            children: [_jsx("b", {
              children: "HR."
            }), " I think about this a lot and I think it’s also a thing that sort of inadvertently has become kind of a defining element of my career as a writer. I’ve written about chicken tenders, and Olive Garden, and the Popeyes chicken sandwich. I eat across the spectrum and it’s been fascinating to me that the things that I write about — what we might call more “mass food” — have often been my most successful or my most resonant pieces."]
          }), _jsxs("div", {
            className: "posrel",
            children: [_jsxs(_components.p, {
              children: ["There’s a kind of “poptimism” in the way we think about food, much like poptimism as a critical phenomenon in music.", _jsx("sup", {
                children: _jsx(_components.a, {
                  href: "#poptimism",
                  children: "5"
                })
              }), " And I think there’s a backlash or retaliation in some ways against the cliché of fancy European food media, or like stereotypical food media, and instead you have the chicken tender, the ode to ugly food, the ode to diner coffee."]
            }), _jsx("aside", {
              children: _jsx("ol", {
                start: "5",
                children: _jsxs("li", {
                  id: "poptimism",
                  children: [_jsx("i", {
                    children: "Poptimism"
                  }), " is the belief that popular music is worthy of serious consideration as an art form. —Ed."]
                })
              })
            })]
          })]
        }), _jsx(FontSection, {
          children: _jsxs(_components.blockquote, {
            children: ["\n", _jsx(_components.p, {
              children: "“I think that writing about food that is underappreciated, even if it is\nextraordinarily popular, gives us space to talk about our daily lives in a way\nthat writing about things that are special, and only things that are special,\ndoes not.”"
            }), "\n"]
          })
        }), _jsxs(FontSection, {
          children: [_jsx(_components.p, {
            children: "I think that writing about food that is underappreciated, even if it is extraordinarily popular, gives us space to talk about our daily lives in a way that writing about things that are special, and only things that are special, does not. And to give the quotidian and the unspecial the same regard and the same grace we give to celebration food or expensive food or rare food and the experiences that attend them."
          }), _jsx(_components.p, {
            children: "So, the small moments of filling up the car with gas with your dad and he buys you a Hostess cherry pie and that becomes your special ritual. That’s not me saying the Hostess cherry pie is the greatest food in the world. That’s me saying that moment with my dad was an important moment. And because food is more than just food, because that moment was important, the Hostess cherry pie is the greatest food in the world."
          }), _jsx(_components.p, {
            children: "So food media moves away from service writing, which is you should buy this because it’s delicious or because it’s cool, and more toward: our lives are valuable. The small parts of our lives are valuable, the quiet parts of our lives are valuable. And the value in that does not demand observation. But when it is observed, it reveals brilliant facets of beauty."
          })]
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs("p", {
          className: "bio",
          children: [_jsx("b", {
            children: "Navneet Alang"
          }), " is a writer based in Toronto. His work has appeared in ", _jsx("cite", {
            children: "New York Magazine"
          }), ", ", _jsx("cite", {
            children: "Eater"
          }), ", the ", _jsx("cite", {
            children: "New Republic"
          }), ", and the ", _jsx("cite", {
            children: "Atlantic"
          }), "."]
        })
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
