/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import cover from "/public/images/kuih-3x3.png";
export const meta = {
  title: "A Beginner’s Guide to&nbsp;Kuih",
  author: "Christian&nbsp;Schwartz, with Veronica&nbsp;Gan and Barnny&nbsp;Lim",
  credit: "Illustrations by Derrick Schultz",
  position: 6,
  type: "listicle",
  category: "Eating",
  slug: "a-beginners-guide-to-kuih",
  paragraphs: "indents",
  byline_family: 1,
  pulled_quotes: true,
  cover: {
    src: cover,
    preview: cover,
    alt: "Illustrations of various kuih arranged in a 3 by 3 grid: pulut panggang, talam pandan, baked ubi kayu, lapis, kosui, serimuka pandan, dadar, pulut inti, and angku.",
    position: "before"
  },
  cssSelectors: [{
    selector: ".body p",
    output: "body"
  }, {
    selector: ".article-title",
    output: "h1"
  }, {
    selector: "h2",
    output: "h2"
  }, {
    selector: ".byline",
    output: "header address",
    excludeFromCSS: true
  }, {
    selector: ".bio",
    output: "footer",
    excludeFromCSS: true
  }, {
    selector: ".bio :is(b, strong)",
    output: ".bio b"
  }, {
    selector: "dl dt",
    output: "dl dt",
    excludeFromCSS: true
  }],
  jsonLD: {
    "author": [{
      "@type": "Person",
      "name": "Christian Schwartz"
    }, {
      "@type": "Person",
      "name": "Veronica Gan"
    }, {
      "@type": "Person",
      "name": "Barnny Lim"
    }]
  },
  excerpt: "Savory and sweet on Eldridge&nbsp;Street.",
  layouts: {
    "Original Sans": {
      title: "A Beginner’s Guide to <br/><b>Kuih</b>"
    },
    "Le Jeune": {
      title: "A Beginner’s Guide to <b>Kuih</b>",
      author: "Christian \nSchwartz, \nwith \nVeronica \nGan & \nBarnny \nLim"
    },
    "Lyon": {
      excerpt: "“A layer of steamed, compressed glutinous rice, salted and often colored with butterfly pea flowers, is topped with steamed sweet pandan custard.”"
    },
    "Eugenia": {
      title: "A Beginner’s Guide to Kuih by Christian Schwartz, with Veronica Gan & Barnny&nbsp;Lim"
    }
  },
  families: {
    text: 1,
    headings: 2,
    pullquotes: 2,
    byline: 1,
    bio: 1
  },
  weights: {
    text: 400,
    hed: 600
  },
  pairings: {
    "Action Text Bright {1.4} [400]": ["Action Condensed Regular <1.7143> {1} [700]", "Action Text Bright <1.1> {1.21} [700]", "Darby Serif Display <1.2857> {1.21} [300]", "Duplicate Ionic <1.0357> {1.15} [500]", "Duplicate Slab <1.0357> {1.15} [200]", "Produkt <0.9643> {1.21} [600]", "Guardian Egyptian Headline <1.0357> {1.21} (italic) [600]"],
    "Caslon Ionic {1.4} [400]": ["Austin <1.2143> {1.1} [200]", "Caslon Ionic <0.88> {1.19} [400]", "Brunel Deck <1.1> {1.28} [600]", "Caslon Doric <0.9643> {1.23} [600]", "Duplicate Sans <1.1429> {1.12} [700]", "Publico Headline <1.1> {1.2} [700]", "Styrene B <0.9286> {1.15} [200]"],
    "Graphik <0.9375> {1.4} [400]": ["Graphik X Condensed <1.7143> {1} [400]", "Graphik {1.185} [600]", "Produkt <0.9643> {1.21} [600]", "Action Condensed Medium <2> {1} [300]", "Algebra <1.0357> {1.21} [700]", "Caponi Display <1.25> {1.1} [400]", "Caponi Slab {1.1} (italic) [600]", "Lyon Display <1.2> {1.21} [200]", "Publico Headline {1.2} [500]", "Canela Deck {1.29} (italic) [300]", "Terza Reader <0.9286> {1.3} [600]", "Feature Deck <1.25> {1.28} [300]", "Brunel Deck <1.1> {1.28} (italic) [600]"],
    "Review <0.9375> {1.4} [400]": ["Review Poster <1.7143> {1} [700]", "Review {1.21} [700]", "Feature Deck <1.2> {1.28} [700]", "Algebra Display <1.0357> {1.21} [100]", "Canela Deck {1.29} [100]", "Eugenio Serif <1.1071> {1.2} [300]", "Austin <1.2143> {1.1} [300]", "Chiswick Headline <1.2> (italic) [600]"],
    "Styrene B {1.4} [400]": ["Feature Deck <1.1> {1.28} [800]", "Styrene A <0.9286> {1.15} [400]", "Algebra <1.0357> {1.21} [600]", "Eugenio Serif <1.1071> {1.2} [600]", "Action Condensed Regular <1.7143> {1} [300]", "Darby Serif Display <1.2857> {1.21} [400]", "Portrait <1.1429> {1.12} [700]", "Le Jeune Deck <1.2> {1.21} [600]"],
    "Darby Serif Text {1.4} [400]": ["Darby Serif Display <1.2857> {1.12} (italic) [300]", "Algebra <1.0357> {1.21} [600]", "Eugenio Serif <1.3> {1.2} [400]", "Action Condensed Regular <1.7143> {1} [400]", "Chiswick Sans <1.35> {1.28} [600]", "Review {1.21} [500]", "Review Condensed <1.2857> {1.21} [200]", "Graphik {1.185} [600]", "Graphik Condensed <1.1429> {1.16} [500]", "Duplicate Sans <1.1429> {1.12} [600]", "Darby Sans <1.0357> {1.2} [400]", "Eugenio Sans <1.15> {1.135} [200]", "Guardian Sans Headline <1.0357> {1.21} [600]", "Marr Sans <1.0357> {1.28} [600]", "Marr Sans Condensed <1.24> {1.16} [600]", "Robinson <1.2857> {1.1} [300]"]
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p",
      a: "a",
      h2: "h2"
    }, props.components), {FontSection, Video} = _components;
    if (!FontSection) _missingMdxReference("FontSection", true);
    if (!Video) _missingMdxReference("Video", true);
    return _jsxs(_Fragment, {
      children: [_jsxs(FontSection, {
        children: [_jsxs("p", {
          className: "no-indent",
          children: [_jsx("i", {
            children: "Kuih"
          }), " is a Malaysian term for a wide variety of bite-sized savory snacks and sweet desserts. Most often steamed, but sometimes baked or grilled, kuih are usually made with rice flour or tapioca starch. They’re flavored with ingredients local to Southeast Asia and are enjoyed any time of day, typically with tea or coffee. Two of the most common flavorings are pandan, a tropical screw-pine plant with fragrant, bright green leaves that have a subtle but distinctive flavor described as “grassy vanilla with a hint of coconut”; and ", _jsx("i", {
            children: "gula melaka"
          }), ", or palm sugar, a dark brown sweetener derived from the sap of sugar palm flowers."]
        }), _jsxs(_components.p, {
          children: ["Veronica Gan opened ", _jsx(_components.a, {
            href: "https://www.kuihcafe.com",
            children: "Kuih Café"
          }), " in a tiny storefront on Eldridge Street in Chinatown in early 2020. I met my friend Rashad there for lunch one day at his suggestion and immediately started following the ", _jsx(_components.a, {
            href: "https://www.instagram.com/kuihcafe/",
            children: "Instagram feed"
          }), " where the café announces its dishes for the week. I try never to miss the salty egg butter chicken or the lontong, a deeply satisfying bright yellow curry soup. And after sampling the cara berlauk, small savory pancakes with curried beef, while researching this article, I now have another dish to plan my weekend around."]
        }), _jsx(_components.p, {
          children: "Although I had seen kuih at Malaysian restaurants before, I felt intimidated by their sheer variety, and since my palate leans more savory than sweet, I tended to pass them by. Veronica’s kuih are pretty and meticulously crafted; she only offers two or three at a time. I couldn’t help but notice how excited other customers were about them, so I started working my way through the menu and quickly realized what I had been missing."
        }), _jsx(_components.p, {
          children: "Thanks to generous input from Veronica and her colleague Barnny Lim, what follows is the introduction to kuih I wish I had read years ago."
        })]
      }), "\n", _jsx(Video, {
        filename: "lapis",
        alt: "Blue-and-white striped lapis kuih with a magenta top, spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "1. Lapis"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "Nine layers of rice flour batter are carefully poured and steamed one at a time. Colors can vary, but the classic version has alternating pink and white layers. Kuih Café uses butterfly pea flowers for a bright blue hue, topped with a pink layer colored with dragon fruit juice."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Vary depending on what is used for coloring; can be butterfly pea, pandan, ube, pumpkin, or others"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Smooth, jiggly, chewy"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "6/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "pandan",
        alt: "Green and white talam pandan kuih spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "2. Talam Pandan"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "This striking two-tone rice-flour kuih features a salty coconut layer over a sweet pandan layer, each thickened with tapioca starch and steamed. The balance of salty and sweet makes this one of my favorites, and it really spotlights the pandan flavor."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Pandan, coconut"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Jiggly, custardy, smooth"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "5/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "baked-ubi",
        alt: "Baked ubi kayu in various shades of brown spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "3. Baked Ubi Kayu"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsxs("p", {
          className: "no-indent",
          children: ["Baked grated cassava kuih with coconut milk, palm sugar, and eggs. Can also be steamed and lightly tossed with shredded coconut. The Filipino snack ", _jsx("i", {
            children: "bibingka"
          }), " is similar."]
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Coconut, palm sugar"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Dense, chewy"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "6/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "kosui",
        alt: "Dark brown kosui topped with grated coconut spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "4. Kosui"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "This very popular kuih, made with rice and tapioca flours and sprinkled with grated coconut, is steamed in round molds. Traditionally flavored with palm sugar, though pandan is also common."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Coconut, palm sugar"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Firm, springy"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "6/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "dardar",
        alt: "Foam-green dadar filled with a brown mixture of coconut and palm sugar spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "5. Dadar"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "Pandan-flavored crepe filled with grated coconut mixed with palm sugar."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Pandan, coconut, palm sugar"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Soft crepe with chewy filling"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "7/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "angku",
        alt: "Orange-brown angku with a tortoise-shell pattern spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "6. Angku"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "Very pretty round kuih often made with a tortoise-shaped mold that leaves an intricate pattern on the top. Bright orange outer skin is made of steamed glutinous rice flour and sweet potato puree. Filling can be grated coconut flavored with palm sugar or ground peanuts, but the most traditional consists of sweetened mung bean paste."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Sweetened rice (skin), filling varies."
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Soft, chewy skin; filling varies."
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "6–7/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "serimuka",
        alt: "Serimuka pandan with a pine-green custard top covering a flecked blue-and-white bottom layer spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "7. Serimuka Pandan"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "A layer of steamed, compressed glutinous rice, salted and often colored with butterfly pea flowers, is topped with steamed sweet pandan custard. The contrast in texture between the rice and custard layers is lovely."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Rice, pandan"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Contrasting smooth/custardy and dense/chewy layers"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "5/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "pulut-inti",
        alt: "Blue-and-white flecked pulut inti covered with a reddish-brown mound of grated coconut and palm sugar spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "8. Pulut Inti"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "This deceptively simple kuih also showcases contrasting textures, with a salty layer of compressed, steamed glutinous rice topped with a heaping layer of grated coconut flavored with palm sugar. Another similar kuih is Pulut Tai Tai, topped with a dollop of pandan kaya, a sweet coconut jam, in place of the grated coconut."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Coconut, palm sugar"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Dense, chewy"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "6 ½/10"
          })]
        })]
      }), "\n", _jsx(Video, {
        filename: "pangang",
        alt: "Long, cylindrical, blue-and-white flecked pulut panggang spinning on a floral background."
      }), "\n", _jsx(FontSection, {
        children: _jsx(_components.h2, {
          children: "9. Pulut Panggang"
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "The only savory kuih on this list, pulut panggang is a long roll of coconut-flavored glutinous rice filled with a mixture of grated coconut, dry shrimp, and chili, all wrapped in banana leaves and pan grilled. It’s salty, savory, spicy, and slightly sweet, with the aroma of banana leaf. I like it best with a cup of coffee."
        }), _jsxs("dl", {
          children: [_jsx("dt", {
            children: "Flavors"
          }), " ", _jsx("dd", {
            children: "Rice, dried shrimp, coconut, chili"
          }), "\n", _jsx("dt", {
            children: "Texture"
          }), " ", _jsx("dd", {
            children: "Dense, chewy, crunchy"
          }), "\n", _jsx("dt", {
            children: "Sweetness"
          }), " ", _jsx("dd", {
            children: "2/10"
          })]
        }), _jsx("p", {
          className: "no-indent",
          children: "Kuih Café is open Friday through Sunday, serving a weekly selection of one or two savory dishes and a small number of kuih, plus a variety of baked goods and seasonal specialties for holidays."
        }), _jsxs("p", {
          className: "bio no-indent",
          children: [_jsx("b", {
            children: "Christian Schwartz"
          }), " is a type designer and a partner in Commercial Type. ", _jsx("b", {
            children: "Veronica Gan"
          }), " is the chef at Kuih Café, and is passionate about making traditional Southeast Asian fare. ", _jsx("b", {
            children: "Barnny Lim"
          }), " is a Swiss culinary school graduate and a retail professional."]
        })]
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
