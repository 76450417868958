/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import cover from "/public/images/burger2.png";
export const meta = {
  title: "Becoming a Regular",
  author: "Helena Fitzgerald",
  position: 2,
  slug: "becoming-a-regular",
  category: "Essay",
  type: "personal-essay",
  paragraphs: "indents",
  byline_family: 1,
  pulled_quotes: true,
  cover: {
    src: cover,
    static: cover,
    alt: "Abstract generative image of a hamburger: a round blob divided in four parts, in browns, oranges, and greens.",
    credit: "Derrick Schultz",
    position: "after"
  },
  cssSelectors: [{
    selector: ".body p",
    output: "body"
  }, {
    selector: ".article-title",
    output: "h1"
  }, {
    selector: ".byline",
    output: "header address",
    excludeFromCSS: true
  }, {
    selector: ".bio",
    output: "footer",
    excludeFromCSS: true
  }, {
    selector: ".bio :is(b, strong)",
    output: "footer",
    excludeFromCSS: true
  }, {
    selector: "blockquote p",
    output: "blockquote"
  }],
  excerpt: "Converting emotions into geography.",
  layouts: {
    "Original Sans": {
      title: "Becoming a <br /><b>Regular</b>"
    },
    "Le Jeune": {
      title: "Becoming a <b>Regular</b>",
      author: "Helena \nFitzgerald"
    },
    "Lyon": {
      excerpt: "“Ongoing, dug-in love is a process of both remembering and forgetting beauty, rewriting over it, and sometimes creating it whole cloth out of a shared history.”"
    },
    "Eugenia": {
      title: "Becoming a Regular, a personal essay by Helena&nbsp;Fitzgerald"
    }
  },
  weights: {
    text: 400,
    byline: 600,
    hed: 500,
    pullquote: 300
  },
  families: {
    text: 1,
    headings: 2,
    pullquotes: 2,
    byline: 1,
    bio: 1
  },
  pairings: {
    "Darby Serif Text {1.4}": ["Canela Deck {1.29}", "Darby Serif Display {1.21}", "Darby Sans <1.0357> {1.2}", "Darby Sans Poster <1.25> {1.2} [200]", "Algebra Display <1.0357> {1.21}", "Eugenio Sans {1.135}", "Chiswick Sans <1.125> {1.28}", "Robinson <1.062> {1.2}", "Caponi Slab <1.1071> {1.1}", "Graphik {1.185} [200]", "Review Condensed <1.25> {1.2}"],
    "Portrait Text <1.1875> {1.4}": ["Review {1.21}", "Review Condensed <1.2> {1.15}", "Portrait <1.1429> {1.12} (italic)", "Graphik", "Graphik Condensed <1.25> {1.15}", "Le Jeune Deck <1.15> {1.21}", "Styrene A <0.9> {1.15}"],
    "Robinson <1.0625> {1.4}": ["Terza Reader <0.9> {1.4}", "Robinson <1.1> {1.2}", "Graphik Condensed <1.3> {1.16}", "Darby Serif Text <1.1> {1.21}", "Caponi Slab <1.2> {1.1}", "Lyon Display <1.12> {1.21} [300]", "Sanomat Sans <1.0714> {1.29} [600]"],
    "Sanomat Sans Text {1.4}": ["Sanomat Sans <1.0714> {1.29}", "Sanomat {1.21}", "Action Condensed Regular <1.7143> {1}", "Action Text Dark <1.2> {1.21} [500]", "Algebra Display <1.12> {1.21} [500]", "Caponi Display <1.275> {1.1}", "Caponi Slab <1.1071> {1.1} [500]", "Portrait <1.1429> {1.12}", "Darby Sans Poster <1.1429> {1.28}", "Duplicate Slab <1.0357> {1.15}", "Lyon Display <1.0357> {1.21}"],
    "Terza Reader <0.9375> {1.425}": ["Graphik", "Terza Reader <1.0625> {1.35} (italic)", "Action Condensed Light <1.7143> {1} [400]", "Algebra <1.0357> {1.21} [600]", "Antique No 6 <1.0357> {1.15}", "Duplicate Sans <1.1429> {1.12}", "Eugenio Sans {1.135} [700]", "Review {1.21} [200]", "Robinson <1.1> {1.21}", "Styrene B {1.15}"],
    "Darby Sans {1.42}": ["Darby Serif Text <1.0625> {1.21}", "Darby Sans Poster <1.25> {1.25}", "Darby Sans <1.0357> {1.2} [500]", "Robinson <1.12> {1.25}", "Portrait <1.4> {1.12}", "Austin News Deck <1.1> {1.29} [300] (italic)", "Frame Head <0.9286> {1.28}", "Publico Headline {1.2}"]
  }
};
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p",
      blockquote: "blockquote",
      a: "a"
    }, props.components), {FontSection} = _components;
    if (!FontSection) _missingMdxReference("FontSection", true);
    return _jsxs(_Fragment, {
      children: [_jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "I have no idea if the burger at Fanelli’s is good, or even if the fries are good, but it doesn’t matter. Fanelli’s sits on a corner in the dead center of Soho, an anachronism in the middle of all the gold-plated shopping and sleek restaurants crowded with wealthy tourists. An old-fashioned dive bar where everything is faded and made of wood, it somehow also manages to fit perfectly into the amusement park of a neighborhood around it, as though it were the entrance to a themed roller coaster about old New York City. Fanelli’s survival in this neighborhood should make it despicable, and yet almost inexplicably it doesn’t. It shouldn’t still manage to be a good bar, or have good food, and yet somehow it is, and it does."
        }), _jsx(_components.p, {
          children: "But of course I am the worst possible judge of that. This is a place that got into my heart early, and created my first ideas of a New York City that was just out of reach. What I know about Fanelli’s is not whether the food is good or the drinks are too expensive now. Instead, I know that when I was right on the edge of being a child and being old enough to long forward into the world, I spent a lot of time there. This slightly embarrassing bar marks time in my continuing life. It has shown up over and over, until I counted on its clumsy black-painted wood doors to create consistency."
        }), _jsx(_components.p, {
          children: "The places to which we return can make a jumble of rushed and random events feel like a story, imbued with the false and meaningful narrative by which a novel hangs together. We use these repetitions to navigate through our lives, to convince ourselves that the small days add up to something. I have used this old bar that tourists go to, a bar that is now across the street from an Apple store, to convince myself that my life connects back to my parents’ lives in the city, to a version of New York that came before me and that I never experienced, to the person I was when I was much younger, and to who I might be as I start to get old here, in the strange new continent of not being quite young in New York. If you asked me to recommend a bar in downtown Manhattan, I would tell you to go to Fanelli’s. If you asked me why, or what was good there, I would have nothing to tell you."
        }), _jsx(_components.p, {
          children: "The summer when I was thirteen, a classmate from middle school and I were both in New York at the same time. We each had family downtown, in a version of Soho that wasn’t quite yet a shopping mall, and we both would escape from our families by sitting in the back booth at Fanelli’s, where they let two teenagers hang out for hours ordering burgers and endless fries. We tried very hard to look like we were older than we were, and we spent long afternoons hoping to see Leo(nardo DiCaprio) from the windows at the back of the bar, walking down Mercer Street. My friend was cool and I was not and suddenly for a few weeks I got to be friends with her, which meant I got to be cool. I associated this overnight and unearned transformation for the rest of my life with my New York City, and although it isn’t fashionable to say it anymore, I don’t think I was wrong."
        }), _jsx(_components.p, {
          children: "We sat in a bar, two kids almost a decade away from being old enough to drink, and ate burgers and fries and sometimes chicken fingers. We ventured out around the neighborhood and looked at lipsticks we couldn’t afford at Anna Sui in their heavy black lacquer cases, their weight like precious stones in our hands, and then we went back to the bar. My friend always acted like she was about to go to the kind of party adults went to at night downtown in New York City, and I believed with my whole heart that my presence was the only thing stopping her from doing so. We ended up back at Fanelli’s instead. I was performing the same kind of migratory ritual that I would find myself performing over and over in adulthood when I became a regular somewhere, when for reasons that never once had anything to do with the quality of the drinks or the food, one bar or another became the space that defined my life and converted emotions into geography."
        }), _jsx(_components.p, {
          children: "But I didn’t know that yet. I had no idea what the processes were that caused things to matter, and how it would work when their gears began to turn. It was the 1990s in New York City. Everything shivered tenuously between old and new versions, between text and revision. Everywhere downtown, you could see the seams, empty lots next to glossy boutiques into and out of which seal-sleek models ducked. In the evenings, restaurants bursting with money and amber light stained laughter and envy out onto the pavement around them; around the corner, a whole street would still be dark windows and burned-out cars. Nobody could really afford to live here anymore, but nobody knew that yet; generations collided with one another across the grid between neighborhoods whose names were changing. Canal Jeans heaved with fifteen-dollar leather jackets and endless denim that didn’t fit me the way it fit my friends. The city was beautiful and I wasn’t, but it didn’t matter any more than it mattered whether or not the burger at Fanelli’s was good, or whether I knew how to talk to people when I met them, or whether I had ever kissed anyone, which I hadn’t. Everything was still in the future; there was still time for all of it. I believed then that all I had to do was wait for it. My parents would show up to find my middle-school friend and me at Fanelli’s and tell us stories about the bar two decades before; I felt like I was finally part of something beyond myself, that I had gotten inside of the story where the real people lived."
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "Nobody could really afford to live here anymore, but nobody knew that yet; generations collided with one another across the grid between neighborhoods whose names were changing."
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "I had no experience of how time passing works or of what it means to get older. I could not have known then that I would enter the adult world, just like I was so impatient to, but that I would do so through a series of bargains, in which I lost things I hadn’t even realized I wanted to keep. One day, sooner than I could have ever imagined, I would live here, and I would go to Fanelli’s and be able to order a beer. I would have friends, and know how to make new ones. I would have kissed a lot of people, and jeans would fit me, but the New York I would live in would never be the city I glimpsed from the window of Fanelli’s as a thirteen-year-old who was made briefly cool by proximity, eating a burger that might have been good and might have been terrible."
        }), _jsx(_components.p, {
          children: "Lots of bars are good, and so are lots of restaurants; the world is full of beautiful meals and spectacular drink menus. These things are rarely, if ever, what makes anyone love a place. Loving a restaurant or a bar because it is good is like loving a person because they are beautiful. At best, it is an unsustainable beginning. Ongoing, dug-in love is a process of both remembering and forgetting beauty, rewriting over it, and sometimes creating it whole cloth out of a shared history. I am pretty sure Fanelli’s is actually a good bar. I know this by a sort of context-reading deduction, by listening to other people talk about it, and even by the simple fact that it is still open, stuck like a huge old oak tree in the corner of Prince and Mercer Streets. But I lost any hope of experiencing it that way years ago. I once went to a very annoying afterparty for a very annoying book event there, and the fact that this was where we had ended up instantly turned a bad night into a good one, because I felt like the fact that I was in this room connected me to a larger story, singing the past and the present into coherence. I have been going to Fanelli’s since I was thirteen years old and someone in my family has been going since long before I was born. When someone says “a bar,” it is the bar I think of first."
        })]
      }), "\n", _jsx(FontSection, {
        children: _jsxs(_components.blockquote, {
          children: ["\n", _jsx(_components.p, {
            children: "Ongoing, dug-in love is a process of both remembering and forgetting beauty, rewriting over it, and sometimes creating it whole cloth out of a shared history."
          }), "\n"]
        })
      }), "\n", _jsxs(FontSection, {
        children: [_jsx("p", {
          className: "no-indent",
          children: "My husband’s and my fourth wedding anniversary was at the end of September. We’d made reservations at a nice restaurant with nice reviews, one of those places where every object is made of velvet or chrome. But that day we got bad news about one of my dad’s oldest friends. She was one of the people who had lived with him in a building on Crosby Street in the time when Soho was galleries and dead factories and burned-out cars sunken into cobblestones. My dad and his first wife and these same old friends might have gone down a block and a half to Fanelli’s at the end of a long afternoon, to tie up the day into some kind of completion, to burn off their dissatisfactions and restless worries by way of cheap drinks at the slow frontier of the night. I imagine they never knew, or thought about, whether Fanelli’s was a good bar. If you go to the same place enough times, out of proximity or convenience, eventually it eclipses other places that might have greater objective merit. This is often how we love people, too, by the repeated actions of small days and necessities, by convenience and proximity, by ending up near them again and again until they become familiar, until their shape becomes the same shape as our lives."
        }), _jsx(_components.p, {
          children: "In the here and the now at the end of September, my dad’s old friend was sick, and her wife asked us to come over to help. Thomas went over and helped; when there was nothing else to do, he left. By then it was dinnertime, and it was raining. I came and met him; neither of us was dressed for a fancy dinner, nor were we in the mood for one. We canceled our reservation. But we were hungry, and sad, and Fanelli’s was right there."
        }), _jsx(_components.p, {
          children: "The outdoor tables were sheltered from the weather by little temporary plastic awnings. The TVs were on; Fanelli’s has never pretended to be anything fancier than a bar someone might frequent in a much smaller town. We sat under the plastic in the rain, and ordered fries and grilled cheese sandwiches. Up and down Mercer Street, the lights splintered and caught like stars. We were sad, so we ate warm fried things, which is one of the few reliable human solutions in this life. It helped and it didn’t. New York for a minute could have been a small town, just the place where I grew up, where the people I love loved each other first, where things happen mainly to do with family, and hospitals, and neighbors. The meal’s only memorable attribute was that it was warm, but the fact of a grilled cheese on a rainy night during a crisis, in the blown-out aftermath when there’s nothing left to do about it, is closer to the whole point of food than any grand culinary achievement will probably ever be. We ate fries and sandwiches, and watched the rain, and eventually we walked to the subway, and went home. Fanelli’s looked the same as it had, unremarkable and unchanged, keeping a record."
        }), _jsxs("p", {
          className: "bio",
          children: [_jsx("b", {
            children: _jsx(_components.a, {
              href: "https://griefbacon.substack.com",
              children: "Helena Fitzgerald"
            })
          }), " is a writer in New York."]
        })]
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
