import Image from "next/image"
import Link from "next/link"
import logo from "../../public/logo.svg"
import SVG, { Props as SVGProps } from "react-inlinesvg"
import { useCallback, useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import { useInterval } from 'usehooks-ts'

export const Header = ({ onRandomize }: { onRandomize: () => void }) => {
  const router = useRouter()
  const [didScroll, setDidScroll] = useState(false)
  const [didClick, setDidClick] = useState(false)
  const intervalTime = (didScroll || didClick) ? null : 10000
  useInterval(() => {
    if (router.pathname === "/") {
      randomize()
    }
  }, intervalTime)
  
  const [rotation, setRotation] = useState(0)
  const randomize = () => {
    setRotation(rotation + 1)
    onRandomize()
  }
  const handleRandomClick = () => {
    setDidClick(true)
    randomize()
  }
  
  useEffect(() => {
    const handleScroll = e => {
      setDidScroll(true)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  useEffect(() => {
    setDidClick(false)
    setDidScroll(false)

  }, [router.pathname])

  const handleToggleTheme = () => {
    document.documentElement.classList.toggle("isDark")
  }

  return (
    <header className="site-header">
      <Link href="/">
        <a className="wordmark">
          <Image src={logo} alt="Commercial Type Logo" priority />
        </a>
      </Link>
      <div className="container">
        <button className="theme-toggle" onClick={handleToggleTheme}>
          Toggle Theme
        </button>
        <button className="random-button" onClick={handleRandomClick} style={{ "--rotation": rotation }} aria-label="Randomize type pairing">
          <SVG src="/icons/refresh.svg" role="presentation" />
        </button>
      </div>
    </header>
  )
}
export default Header
